import React, { useEffect, useState } from 'react';
import CpHeader from "../component/cp_header"
import CpFooter from "../component/cp_footer"
import CpLoading from "../component/cp_loading"
import { loadContent } from "../lib/libFly";

function PPricing(props) {
    const [data, setData] = useState();
    useEffect(() => {
        let aa_ = loadContent("pricing");
        aa_.then((result) => {
            if (result.title !== undefined && result.title[props.lang] !== undefined)
                document.title = result.title[props.lang];

            setData(result);
        })

        window.abcC();
    }, [props.lang]);

    return (<React.Fragment>
        <div className="main">
            <CpHeader {...props}></CpHeader>
            <section className="py-20">
                <div className="container">
                    <div className="max-w-lg mb-16">
                        <h2 className="mb-4 text-3xl lg:text-4xl font-bold font-heading wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".1s">
                            <span>Đăng ký dịch vụ của chúng tôi </span>
                            <span className="text-blue-500">ngay hôm nay</span>
                            <span> để được ưu đãi</span>
                        </h2>
                        <p className="text-base lg:text-lg text-blueGray-400 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s">Tư vấn chăm sóc khách hàng nhanh chóng, liên tục.<br /> Hỗ trợ doanh nghiệp thực hiện chiến lược omni channel.</p>
                    </div>
                    <div className="bg-white border border-gray-200 rounded shadow">
                        <table className="border-solid border-2 border-sky-500" width="100%" style={{ "borderColor:": "red" }}>
                            <thead>
                                <tr>
                                    <th className="border-solid border-2 border-sky-500" style={{ "borderWidth": "1px 0px 1px 1px" }}>{data && data.col0[props.lang]}</th>
                                    <th className="border-solid border-2 border-sky-500" width="20%" style={{ "borderWidth": "1px 0px 1px 0px" }}>{data && data.col11[props.lang]}<br />{data && data.col12[props.lang]}</th>
                                    <th className="border-solid border-2 border-sky-500" width="20%" style={{ "borderWidth": "1px 0px 1px 0px" }}>{data && data.col21[props.lang]}<br />{data && data.col22[props.lang]}</th>
                                    <th className="border-solid border-2 border-sky-500" width="20%" style={{ "textAlign": "center", "borderWidth": "1px 0px 1px 0px" }}>{data && data.col31[props.lang]}<br />{data && data.col32[props.lang]}</th>
                                    <th className="border-solid border-2 border-sky-500" width="40px" style={{ "borderWidth": "1px 1px 1px 0px" }}>
                                        <img src="/assets/imgs/like.png" alt="" style={{ "float": "right", "marginLeft": "10px" }} className="wow animate__animated animate__fadeIn" data-wow-delay=".2s" width="30px" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.list_pricing.map((item, i) =>
                                    <tr key={i}>
                                        <td className="border-sky-500" height="50px" style={{ "borderWidth": "1px 0px 1px 1px", "textAlign": "right" }}>
                                            {item && item["show2"] && <img src="/assets/imgs/2eyes.png" alt="" style={{ "float": "right", "marginLeft": "10px" }} className="wow animate__animated animate__fadeIn" data-wow-delay=".2s" width="30px" />}
                                            {item && item["show2"] ? <span style={{ "float": "right" }}>{item.title[props.lang]}</span> : item.title[props.lang]}
                                        </td>
                                        <td className="border-sky-500" style={{ "borderWidth": "1px 0px 1px 0px" }}>{item && item["1"] && <img style={{ "display": "block", "marginLeft": "auto", "marginRight": "auto" }} src="/assets/imgs/check_pricing.png" alt="" className="wow animate__animated animate__fadeIn" data-wow-delay=".2s" width="40px" />}</td>
                                        <td className="border-sky-500" style={{ "borderWidth": "1px 0px 1px 0px" }}>{item && item["2"] && <img style={{ "display": "block", "marginLeft": "auto", "marginRight": "auto" }} src="/assets/imgs/check_pricing.png" alt="" className="wow animate__animated animate__fadeIn" data-wow-delay=".2s" width="40px" />}</td>
                                        <td className="border-sky-500" style={{ "borderWidth": "1px 0px 1px 0px" }}>{item && item["3"] && <img style={{ "display": "block", "marginLeft": "auto", "marginRight": "auto" }} src="/assets/imgs/check_pricing.png" alt="" className="wow animate__animated animate__fadeIn" data-wow-delay=".2s" width="40px" />}</td>
                                        <td style={{ "borderWidth": "1px 1px 1px 0px" }}></td>
                                    </tr>)}
                                <tr>
                                    <td className="border-sky-500" height="50px" style={{ "borderWidth": "1px 0px 1px 1px" }}></td>
                                    <td className="border-sky-500" style={{ "borderWidth": "1px 0px 1px 0px" }}><a className="block mb-2 py-4 text-center hover:text-white font-semibold bg-blueGray-200 hover:bg-blue-500 rounded" href="/login">{data && data.try_now[props.lang]}</a></td>
                                    <td className="border-sky-500" style={{ "borderWidth": "1px 0px 1px 0px" }}><a className="block mb-2 py-4 text-center hover:text-white font-semibold bg-blueGray-200 hover:bg-blue-500 rounded" href="/login">{data && data.try_now[props.lang]}</a></td>
                                    <td className="border-sky-500" style={{ "borderWidth": "1px 0px 1px 0px" }}><a className="block mb-2 py-4 text-center hover:text-white font-semibold bg-blueGray-200 hover:bg-blue-500 rounded" href="/login">{data && data.try_now[props.lang]}</a></td>
                                    <td style={{ "borderWidth": "1px 1px 1px 0px" }}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
            <CpFooter {...props}></CpFooter>
        </div>
        <CpLoading {...props}></CpLoading>
    </React.Fragment >)
};

export default PPricing;