import React, { memo } from 'react';

const cp_footer = (props) => <section className="relative bg-blueGray-100 py-4">
    <div className="container">
        <div className="flex flex-col lg:flex-row lg:flex-nowrap justify-between w-full px-2 items-center">
            {/* phần bên trái */}
            <div className="w-full lg:w-1/2 px-2 max-w-lg lg:pl-6 mx-auto lg:mx-0 text-center lg:text-left mb-4 lg:mb-0">
                <a className="inline-block mx-auto lg:ml-0 text-2xl font-semibold leading-none wow animate__animated animate__fadeInUp" data-wow-delay=".1s" href="/">
                    <img className="h-8" src="/assets/imgs/logos/Handsup.svg" alt="Logo" />
                </a>
                <div className="hidden lg:block wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                    <p className="text-xs text-blueGray-400">
                        &copy; {(props.lang && props.data_master?.footer?.['rights']?.[props.lang]) || '2024. All rights reserved by Sóc Bay.'}
                    </p>
                </div>
            </div>

            {/* phần bên phải */}
            <div className="w-full lg:w-1/2 px-2 lg:pl-6">
                <div className="w-full lg:w-auto wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                    <h5 className="mb-4 text-md font-heading text-center">
                        {(props.lang && props.data_master?.footer?.['follow-us']?.[props.lang]) || 'Follow us'}
                    </h5>
                    <ul className="flex flex-row flex-wrap justify-center lg:justify-start">
                        <li>
                            <a className="inline-block px-1" href="https://www.facebook.com/handsupchat" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/imgs/icons/facebook-blue.svg" className="w-8 h-8" alt="Facebook" />
                            </a>
                        </li>
                        <li>
                            <a className="inline-block px-1" href="https://www.instagram.com/handsup_chat?igsh=ZG5vYW5vNHVkYnZp" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/imgs/icons/instagram-blue.svg" className="w-8 h-8" alt="Instagram" />
                            </a>
                        </li>
                        <li>
                            <a className="inline-block px-1" href="https://x.com/Handsupchat" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/imgs/icons/twitter_x.svg" className="w-8 h-8" alt="Twitter X" />
                            </a>
                        </li>
                        <li>
                            <a className="inline-block px-1" href="https://www.threads.net/@handsup_chat" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/imgs/icons/Threads.svg" className="w-8 h-8" alt="Threads" />
                            </a>
                        </li>
                    </ul>
                    <h5 className="pt-3 text-md font-heading text-center">
                        <a href={"/s" + (props.lang && props.data_master.route.PrivacyPolicyandTerms.link[props.lang])} rel="noopener noreferrer">
                            {(props.lang && props.data_master.route.PrivacyPolicyandTerms.title[props.lang])}
                        </a>
                    </h5>
                </div>
            </div>
        </div>
    </div>

</section>

export default memo(cp_footer);