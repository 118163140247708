// import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import CpDisposableItemsView from "../view_prod/cp_disposable_items_view";
import chatAI from "./obChatAI";
var data_spAI = {};
var chatAI_ob = new chatAI();
function CpDisposableItems(props) {
  const [inputInfoAI, setInputInfoAI] = useState(props.data.demo_gioithieu_disposable[props.lang]);
  // khởi tạo in ra lỗi khi nhập inputInfoAI
  const [inputInfoAIError, setInputInfoAIError] = useState("");

  //COD
  const [inputInfoCOD, setInputInfoCOD] = useState(props.data.demo_COD_disposable[props.lang]);
  const [inputInfoCODError, setInputInfoCODError] = useState("");

  const [selectedProd, setSelectedProd] = useState("sp1");

  const [cpViewSpStatusSp1, setCpViewSpStatusSp1] = useState(false);
  const [cpViewSpStatusSp2, setCpViewSpStatusSp2] = useState(false);
  const [cpViewSpStatusSp3, setCpViewSpStatusSp3] = useState(false);

  const handleCp_inputInfoAI = (txt_) => {
    setInputInfoAIError("");
    setInputInfoAI(txt_);
    if (txt_.length > 200 && txt_.length <= 500) {
      props.call_InputInfo(txt_);
    } else {
      let mess_r = props.data.form_input_2_title0_error[props.lang];
      mess_r = mess_r.replace("{1}", "" + txt_.length);
      setInputInfoAIError(mess_r);
    }
  };

  const handleCp_inputInfoCOD = (txt_) => {
    setInputInfoCODError("");
    setInputInfoCOD(txt_);
    props.call_InputInfoCOD(txt_);
  }
  const handleCpSale1 = (key_, data_spAI_of_key) => {
    data_spAI[key_] = data_spAI_of_key;

    //vì useState có độ trễ nên cần khai báo vào biến trung gian
    let c_cpViewSpStatusSp1 = cpViewSpStatusSp1;
    let c_cpViewSpStatusSp2 = cpViewSpStatusSp2;
    let c_cpViewSpStatusSp3 = cpViewSpStatusSp3;

    if (key_ === "sp1") {
      setCpViewSpStatusSp1(true);
      c_cpViewSpStatusSp1 = true;
    }

    if (key_ === "sp2") {
      setCpViewSpStatusSp2(true);
      c_cpViewSpStatusSp2 = true;
    }

    if (key_ === "sp3") {
      setCpViewSpStatusSp3(true);
      c_cpViewSpStatusSp3 = true;
    }

    //set cho trang gọi
    //luôn luôn cập nhập tất cả
    props.call_CpSale(data_spAI);

    props.call_CpSale_status(
      c_cpViewSpStatusSp1 || c_cpViewSpStatusSp2 || c_cpViewSpStatusSp3
    );
  };

  useEffect(() => {
    // chatAI_ob.test("Ok a");
    props.call_InputInfo(props.data.demo_gioithieu_disposable[props.lang]);
    props.call_InputInfoCOD(props.data.demo_COD_disposable[props.lang]);
  }, []);
  return (
    <div
      className="flex flex-wrap mb-4 -mx-3 wow animate__animated animate__fadeInUp animated"
      data-wow-delay=".3s"
    >
      <div className="w-full lg:w-1 px-3 mb-12 lg:mb-10">
        <span className="mr-12 font-semibold">
          {props.data.form_input_2_title0[props.lang]}
        </span>
        <br />
        <span className="mr-4 text-sm">{props.data.sp_support2[props.lang]}</span>
        <textarea
          value={inputInfoAI}
          onChange={(e) => handleCp_inputInfoAI(e.target.value)}
          className="w-full h-full p-4 text-xs font-semibold leading-none resize-none bg-blueGray-50 rounded outline-none"
          placeholder=""
        ></textarea>
        {inputInfoAIError && (
          <span style={{ color: "#ff000096" }}>{inputInfoAIError}</span>
        )}
      </div>
      <div className="w-full lg:w-1 px-3 mb-12 mt-6 lg:mb-10">
        <span className="mr-12 font-semibold">{props.data.form_input_2_title2[props.lang]}</span>
        <br />
        <span className="mr-4 text-sm">{props.data.form_input_2_title2_note1[props.lang]}</span> <br />
        <span className="mr-4 text-sm">{props.data.form_input_2_title2_note2[props.lang]}</span>
        <textarea value={inputInfoCOD} onChange={e => handleCp_inputInfoCOD(e.target.value)} className="w-full h-20 p-4 text-xs font-semibold leading-none resize-none bg-blueGray-50 rounded outline-none" placeholder=""  ></textarea>
        {inputInfoCODError && (<span style={{ color: "#ff000096" }}>{inputInfoCODError}</span>)}
      </div>
      <div className="mb-4 text-sm px-3">
        <span className="mr-4 font-semibold">
          {props.data.selected_prod123[props.lang]}
        </span>
        <label className="mr-4">
          <input
            className="mr-1"
            type="radio"
            name="listProdRadio"
            value="sp1"
            checked={selectedProd === "sp1"}
            onChange={(e) => setSelectedProd(e.target.value)}
          />
          <span>
            {cpViewSpStatusSp1
              ? props.data.selected_prod1[props.lang]
              : props.data.form_input_2_sale5[props.lang]}
          </span>
        </label>
        <label className="mr-4">
          <input
            className="mr-1"
            type="radio"
            name="listProdRadio"
            value="sp2"
            checked={selectedProd === "sp2"}
            onChange={(e) => setSelectedProd(e.target.value)}
          />
          <span>
            {cpViewSpStatusSp2
              ? props.data.selected_prod2[props.lang]
              : props.data.form_input_2_sale6[props.lang]}
          </span>
        </label>
        <label className="mr-4">
          <input
            className="mr-1"
            type="radio"
            name="listProdRadio"
            value="sp3"
            checked={selectedProd === "sp3"}
            onChange={(e) => setSelectedProd(e.target.value)}
          />
          <span>
            {cpViewSpStatusSp3
              ? props.data.selected_prod3[props.lang]
              : props.data.form_input_2_sale7[props.lang]}
          </span>
        </label>
      </div>
      <br />
      <CpDisposableItemsView
        {...props}
        handleCpSale1={handleCpSale1}
        isDisplay={selectedProd === "sp1" ? false : true}
        sp_id={"sp1"}
      ></CpDisposableItemsView>
      <CpDisposableItemsView
        {...props}
        handleCpSale1={handleCpSale1}
        isDisplay={selectedProd === "sp2" ? false : true}
        sp_id={"sp2"}
      ></CpDisposableItemsView>
      <CpDisposableItemsView
        {...props}
        handleCpSale1={handleCpSale1}
        isDisplay={selectedProd === "sp3" ? false : true}
        sp_id={"sp3"}
      ></CpDisposableItemsView>
    </div>
  );
}

export default CpDisposableItems;
