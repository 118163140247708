import React, { useEffect, useState } from 'react';
import CpHeader from "../component/cp_header"
import CpFooter from "../component/cp_footer"
import CpLoading from "../component/cp_loading"
import { loadContent } from "../lib/libFly";

function PAbout(props) {
    const [data, setData] = useState();
    useEffect(() => {
        let aa_ = loadContent("about");
        aa_.then((result) => {
            if (result.title !== undefined && result.title[props.lang] !== undefined)
                document.title = result.title[props.lang];
            setData(result);
        })

        window.abcC();
    });

    return (<React.Fragment>
        <div className="main">
            <CpHeader {...props}></CpHeader>
            <section className="relative -mt-24 pt-24">
                <div className="hidden lg:block absolute inset-0 w-1/2 ml-auto bg-blueGray-100 z-0" style={{ "zIndex": "-1" }}></div>
                <div className="container">
                    <div className="flex flex-wrap items-center -mx-3">
                        <div className="w-full lg:w-1/2 px-3">
                            <div className="py-12">
                                <div className="max-w-lg lg:max-w-md mx-auto lg:mx-0 mb-8 text-center lg:text-left">
                                    <h2 className="text-3xl lg:text-4xl mb-4 font-bold font-heading wow animate__animatedanimated animate__fadeIn">Committed to <span className="text-blue-500">People</span>, <br />Committed to the future</h2>
                                    <p className="text-blueGray-400 leading-relaxed wow animate__animatedanimated animate__fadeIn">We are <strong className="text-blue-500">Monst</strong>, a Creative Design <span className="typewrite d-inline text-brand" data-period="3000" data-type='["Web Agency", "Social Marketing" ]'></span></p>
                                    <p className="text-blueGray-400 leading-relaxed wow animate__animatedanimated animate__fadeIn mt-3 text-sm">Helping you maximize operations management with digitization {data && data.content[props.lang]}</p>
                                </div>
                                <div className="text-center lg:text-left">
                                    <a className="tracking-wide hover-up-2 block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded wow animate__animatedanimated animate__fadeInUp" href="/key-features">Key Features</a>
                                    <a className="block hover-up-2 sm:inline-block py-4 px-8 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white border border-blueGray-200 hover:border-blueGray-300 rounded wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s" href="/how-we-work">How We Work?</a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-3 lg:bg-blueGray-10 mb-12 lg:mb-0 pb-10">
                            <div className="flex items-center justify-center">
                                <img className="lg:max-w-lg" src="/assets/imgs/illustrations/team.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-6 pb-2">
                <div className="container">
                    <div className="flex flex-wrap justify-between pt-8 pb-16">
                        <div className="flex w-1/2 lg:w-auto py-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".2s">
                            <div className="flex justify-center items-center bg-blueGray-50 text-blue-500 rounded-xl h-12 w-12 sm:h-24 sm:w-24">
                                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                                </svg>
                            </div>
                            <div className="sm:py-2 ml-2 sm:ml-6">
                                <span className="sm:text-2xl font-bold font-heading">+ </span><span className="sm:text-2xl font-bold font-heading count">150</span>
                                <p className="text-xs sm:text-base text-blueGray-400">Annual Partner</p>
                            </div>
                        </div>
                        <div className="flex w-1/2 lg:w-auto py-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".4s">
                            <div className="flex justify-center items-center bg-blueGray-50 text-blue-500 rounded-xl h-12 w-12 sm:h-24 sm:w-24">
                                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"></path>
                                </svg>
                            </div>
                            <div className="sm:py-2 ml-2 sm:ml-6">
                                <span className="sm:text-2xl font-bold font-heading">+ </span><span className="sm:text-2xl font-bold font-heading count">58</span><span className="sm:text-2xl font-bold font-heading"> k </span>
                                <p className="text-xs sm:text-base text-blueGray-400">Completed Projects</p>
                            </div>
                        </div>
                        <div className="flex w-1/2 lg:w-auto py-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".6s">
                            <div className="flex justify-center items-center bg-blueGray-50 text-blue-500 rounded-xl h-12 w-12 sm:h-24 sm:w-24">
                                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path>
                                </svg>
                            </div>
                            <div className="sm:py-2 ml-2 sm:ml-6">
                                <span className="sm:text-2xl font-bold font-heading">+ </span><span className="sm:text-2xl font-bold font-heading count">500</span>
                                <p className="text-xs sm:text-base text-blueGray-400">Happy Customers</p>
                            </div>
                        </div>
                        <div className="flex w-1/2 lg:w-auto py-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".8s">
                            <div className="flex justify-center items-center bg-blueGray-50 text-blue-500 rounded-xl h-12 w-12 sm:h-24 sm:w-24">
                                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                                </svg>
                            </div>
                            <div className="sm:py-2 ml-2 sm:ml-6">
                                <span className="sm:text-2xl font-bold font-heading">+ </span><span className="sm:text-2xl font-bold font-heading count">320</span>
                                <p className="text-xs sm:text-base text-blueGray-400">Research Work</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-20 bg-blue-200" id="how-we-work">
                <div className="container">
                    <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-12">
                        <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                            <h2 className="text-3xl md:text-4xl font-bold font-heading wow animate__animatedanimated animate__fadeInDown">
                                <span>We are</span>
                                <span className="text-blue-500">awesome team</span><br />
                                <span>for your business dream</span>
                            </h2>
                        </div>
                        <div className="w-full lg:w-1/2 lg:pl-16">
                            <p className="text-blueGray-400 leading-loose wow animate__animatedanimated animate__fadeInUp">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus eget justo et iaculis. Quisque vitae nulla malesuada, auctor arcu vitae, luctus nisi. Sed elementum vitae ligula id imperdiet.</p>
                        </div>
                    </div>
                    {/* XOÁ Xác thực người dùng - Điền Thông tin - Bắt đầu chat */}
                    {/* <div className="flex flex-wrap -mx-3 -mb-6 text-center">
                        <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s">
                            <div className="p-12 bg-white shadow rounded">
                                <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">1</div>
                                <img className="h-48 mx-auto my-4" src="assets/imgs/illustrations/eating.svg" alt="" />
                                <h3 className="mb-2 font-bold font-heading">Project Initialization</h3>
                                <p className="text-sm text-blueGray-400 leading-relaxed">Sed ac magna sit amet risus tristique interdum at vel velit. In hac habitasse platea dictumst.</p>
                            </div>
                        </div>
                        <div className="hover-up-5 w-full md:w-1/2 lg:w-1/3 px-3 mb-6 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".5s">
                            <div className="p-12 bg-white shadow rounded">
                                <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">2</div>
                                <img className="h-48 mx-auto my-4" src="assets/imgs/illustrations/space.svg" alt="" />
                                <h3 className="mb-2 font-bold font-heading">Looking for Creative</h3>
                                <p className="text-sm text-blueGray-400 leading-relaxed">Sed ac magna sit amet risus tristique interdum at vel velit. In hac habitasse platea dictumst.</p>
                            </div>
                        </div>
                        <div className="hover-up-5 w-full lg:w-1/3 px-3 mb-6">
                            <div className="p-12 bg-white shadow rounded wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".7s">
                                <div className="flex w-12 h-12 mx-auto items-center justify-center text-blue-800 font-bold font-heading bg-blue-200 rounded-full">3</div>
                                <img className="h-48 mx-auto my-4" src="assets/imgs/illustrations/tasks.svg" alt="" />
                                <h3 className="mb-2 font-bold font-heading">Market Development</h3>
                                <p className="text-sm text-blueGray-400 leading-relaxed">Sed ac magna sit amet risus tristique interdum at vel velit. In hac habitasse platea dictumst.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
            <CpFooter {...props}></CpFooter>
        </div>
        <CpLoading {...props}></CpLoading>
    </React.Fragment >)
};

export default PAbout;