import React, { useEffect } from 'react';

import UserChat from "../component/widget_chat/user_chat.js"
import ListUser from '../component/widget_chat/list_user.js'
import MyChat from '../component/widget_chat/my_chat.js'
import CpLoading from "../component/cp_loading"
var data = {
    config: {
        max_clear: 100//lich su chat theo user
    },
    list_user: [
        {
            id: "1a",
            name: "Thành",
            avatar: "https://imgur.com/BNBeftW.jpg"
        },
        {
            id: "2a",
            name: "Tấn",
            avatar: "https://imgur.com/ai2vXoq.jpg"
        },
        {
            id: "3a",
            name: "Quang",
            avatar: "https://imgur.com/ZFSWX9x.jpg"
        }
    ],
    list_chat: {
        "1a": {
            "info": {
            },
            "hist_chat": [
                {
                    content: "Xin chào",
                    time: "Sun, Aug 30 | 15:41"
                },
                {
                    content: "Tôi muốn mua chiếc áo này",
                    time: "Sun, Aug 30 | 15:44"
                },
                {
                    content: "Cảm ơn!!!",
                    time: "Sun, Aug 30 | 15:46"
                }
            ]
        },
        "2a": {
            "info": {
            },
            "hist_chat": [
                {
                    content: "Xin chào",
                    time: "Sun, Aug 30 | 15:41"
                },
                {
                    content: "Xin Tạm biệt",
                    time: "Sun, Aug 30 | 15:45"
                }
            ]
        }
    },
    my_chat: {
        "1a": {
            "info": {
            },
            "mychat": [
                {
                    content: "Chào bạn, Bạn cần gì???",
                    time: "Sun, Aug 30 | 15:41"
                },
                {
                    content: "Chiếc áo này có giá hiện tại là XX, chúng tôi sẽ giao cho anh trong vòng 1 ngày tới!",
                    time: "Sun, Aug 30 | 15:45"
                },
                {
                    content: "Cảm ơn vì đã mua sản phẩm. ",
                    time: "Sun, Aug 30 | 15:48"
                },
            ]
        },
        "2a": {
            "info": {
            },
            "mychat": [
                {
                    content: "Xin chào",
                    time: "Sun, Aug 30 | 15:41"
                },
                {
                    content: "Xin Tạm biệt",
                    time: "Sun, Aug 30 | 15:45"
                }
            ]
        }
    }
}

function PChat(props) {
    useEffect(() => {
        document.title = ``;
        window.abcC();
    }, []);
    return (<React.Fragment>
        <div id="app" className="app">
            <section id="main-left" className="main-left">
                <div id="header-left" className="header-left">
                    <span className="glyphicon glyphicon-menu-hamburger hamburger-btn"></span>
                    <span className="glyphicon glyphicon-search search-btn"></span>
                    <span className="glyphicon glyphicon-option-vertical option-btn"></span>
                </div>

                <div id="chat-list" className="chat-list">
                    {data.list_user.map((item, i_c) => <ListUser data_user={item} key={i_c}></ListUser>)}
                </div>

                <div id="self-info" className="self-info">

                    <div className="profile your-photo">
                        <img src="/assets/imgs/chat/ava4.jpg" alt="" />
                    </div>

                    <h4 className="name your-name">Minh Trí</h4>
                    <span className="glyphicon glyphicon-cog"></span>
                </div>
            </section>
            <section id="main-right" className="main-right">
                <div id="header-right" className="header-right">

                    <div id="header-img" className="profile header-img">
                        {data.list_user && <img src={data.list_user[0].avatar} alt="" />}
                    </div>
                    {data.list_user && <h4 className="name friend-name">{data.list_user[0].name}</h4>}

                    <div className="some-btn">
                        <span className="glyphicon glyphicon-facetime-video"></span>
                        <span className="glyphicon glyphicon-earphone"></span>
                        <span className="glyphicon glyphicon-option-vertical option-btn"></span>
                    </div>
                </div>
                <div id="chat-area" className="chat-area">
                    <UserChat
                        data_content={data.list_chat["1a"].hist_chat[0]}
                        user_data={data.list_user.find(user => user.id === "1a")} // lấy ảnh và tên
                    />
                    <MyChat data_content={data.my_chat["1a"].mychat[0]} />
                    <UserChat
                        data_content={data.list_chat["1a"].hist_chat[1]}
                        user_data={data.list_user.find(user => user.id === "1a")}
                    />
                    <MyChat data_content={data.my_chat["1a"].mychat[1]} />

                    <UserChat
                        data_content={data.list_chat["1a"].hist_chat[2]}
                        user_data={data.list_user.find(user => user.id === "1a")}
                    />

                    <MyChat data_content={data.my_chat["1a"].mychat[2]} />

                </div>
                <div id="typing-area" className="typing-area">
                    <input id="type-area" className="type-area" placeholder="Type something..." />
                    <div className="attach-btn">
                        <span className="glyphicon glyphicon-paperclip file-btn"></span>
                        <span className="glyphicon glyphicon-camera"></span>
                        <span className="glyphicon glyphicon-picture"></span>
                    </div>
                    <span className="glyphicon glyphicon-send send-btn"></span>
                </div>
            </section>
        </div>

        <div id="creator" className="creator">
            <p>&copy; 2024 | All rights reserved by Sóc Bay.</p>
        </div>

        <CpLoading {...props}></CpLoading>
    </React.Fragment >
    )
};

export default PChat;