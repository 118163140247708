export async function loadContent(key_content) {
    let response = await fetch('/data/' + key_content + ".json");
    let source = await response.json();
    // console.log("source===>", source.title);
    return source;
    // const response = await fetch('/data/' + key_content + ".json", {
    //     headers: {
    //         method: "GET",
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json'
    //     }
    // });
    // let data = await response.json();
    // return data;
    // data = JSON.stringify(data);
    // data = JSON.parse(data);
}

export function markdownForTemplate(data) {
    var arr_html = ["<span class='text-blue-500'>", "</span>"];
    var arr_key = ["**+", "**-"];
    //thay thế tất cả từ key theo mã markdown của template quy định
    for (let i in data)
        for (let j in data[i]) {
            for (let k = 0; k < arr_key.length; k++)
                if (data[i][j].includes(arr_key[k]))
                    data[i][j] = data[i][j].replaceAll(arr_key[k], arr_html[k]);

        }
}