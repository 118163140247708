import React, { useEffect, useState } from "react";
import chatAI from "../chat_ai/obChatAI";
import obViewProd from "./ob_view_prod";

var chatAI_ob = new chatAI();
var obViewProd_ob = new obViewProd();
function CpDisposableItemsView(props) {
  const [spUrl, setSpUrl] = useState("");
  const [spUrlStatus, setSpUrlStatus] = useState(true);
  const [spSKU, setSpSKU] = useState("");
  const [spError, setSpError] = useState("");
  const [spTitle, setSpTitle] = useState("");
  const [spBrand, setSpBrand] = useState("");
  const [spShopLocation, setSpShopLocation] = useState("");
  const [spDes, setSpDes] = useState("");
  const [spImg, setSpImg] = useState("");

  const handlegetUrlMaster = () => {
    setSpError("");
    setSpTitle("");
    setSpBrand("");
    setSpShopLocation("");
    setSpDes("");
    setSpImg("");
    setSpSKU("");
    let str_spUrl = spUrl.trim();
    if (str_spUrl === "") {
      setSpError(props.data.sp_sp_error1[props.lang]);
      return;
    }

    let list_check = ["shopee.vn", "lazada.vn", "tiki.vn", "amazon.com"];
    let link_domain = "";
    if (
      !list_check.some((x) => {
        if (str_spUrl.includes(x) === true) {
          link_domain = x;
          return true;
        }
        return false;
      })
    ) {
      setSpError(props.data.sp_sp_error2[props.lang]);
      return;
    }
    if (link_domain === "") return;

    // fetch('/data/tiki.txt', {
    //   headers: {}
    // }
    // ).then((response) => response.text())
    //   .then((text) => {
    //     console.log(text);
    //     tikiLink(text);
    //   });
    // // var xmlhttp;
    // // xmlhttp = new XMLHttpRequest();
    // // xmlhttp.onreadystatechange = () => {
    // //   if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {

    // //     let txt_ = xmlhttp.responseText;
    // //     console.log("txt_===>", txt_);
    // //     if (link_domain === "shopee.vn") shopeeLink(txt_);
    // //     else if (link_domain === "lazada.vn") lazadaLink(txt_);
    // //     else if (link_domain === "tiki.vn") tikiLink(txt_);
    // //     else if (link_domain === "amazon.com") amazonLink(txt_);
    // //     else noneLink();
    // //   }
    // //   // else
    // //   //     console.log("===>", xmlhttp.readyState, "==", xmlhttp.responseText)
    // // };
    // // xmlhttp.open("GET", spUrl, true);
    // // xmlhttp.send();
    if (link_domain === "shopee.vn") {
      var xmlhttp;
      xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = () => {
        setSpUrlStatus(true);
        if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
          let txt_ = xmlhttp.responseText;
          if (link_domain === "shopee.vn")
            shopeeLink(txt_);
          else if (link_domain === "lazada.vn")
            lazadaLink(txt_);
          else if (link_domain === "tiki.vn")
            tikiLink(txt_);
          else if (link_domain === "amazon.com")
            amazonLink(txt_);
          else
            noneLink();
        }
        else
          setSpError(props.data.sp_error_bad_request[props.lang]);
      }
      xmlhttp.open("GET", str_spUrl, true);
      xmlhttp.send();
    }
    else {
      fetch("https://handsupchat.com/pyform-data", {
        method: "POST",
        body: JSON.stringify({
          "key__request": "SBCommercialGetLink",
          "data__request": {
            "link": str_spUrl,
          }
        }),
        headers: {
          "GooPyForm": "IsGOO",
          "Accept": "text/plain",
          "Content-Type": "application/json"
        }
      }).then((response) => response.json()).then((json) => {
        setSpUrlStatus(true);
        if (json.err === "00" && json.messOk === "readOk") {
          let txt_ = json.data;
          if (link_domain === "shopee.vn")
            shopeeLink(txt_);
          else if (link_domain === "lazada.vn")
            lazadaLink(txt_);
          else if (link_domain === "tiki.vn")
            tikiLink(txt_);
          else if (link_domain === "amazon.com")
            amazonLink(txt_);
          else
            noneLink();
        }
      });
    }
  };
  const noneLink = () => { };
  const lazadaLink = (txt_) => {
    //Tấn
    let item__ = obViewProd_ob.lazada_vn_link(txt_, setSpError, props)
    if (item__ === null) {
      setSpError(props.data.sp_error_bad_request[props.lang]);
      return;
    }



    setSpSKU("" + item__.sku);
    setSpError("");
    setSpTitle(item__.title);
    setSpBrand(item__.option1);
    setSpShopLocation(item__.option2);


    let des_ = item__.description;
    console.log("des_ ==>", des_)
    //thay thế \n+
    des_ = des_.replaceAll('\n', '<br />')
    setSpDes(item__.description.replaceAll('\n', '<br />'));
    setSpImg(item__.img_main);

    props.handleCpSale1(props.sp_id, item__);
  };
  const amazonLink = (txt_) => {
    //Thông
    let item__ = obViewProd_ob.amazon_com_link(txt_, setSpError, props)
    if (item__ === null) {
      setSpError(props.data.sp_error_bad_request[props.lang]);
      return;
    }

    // Set the state values
    setSpSKU("" + item__.sku);
    setSpError("");
    setSpTitle(item__.title);
    setSpBrand(item__.brand);
    setSpShopLocation(item__.shopLocation);
    setSpDes(item__.description.replaceAll('\n', '<br />'));
    setSpImg(item__.img);

    let des_ = item__.description
    console.log("des_ ==>", des_)
    // //Xử lý /n++
    // //thay thế html 
    des_ = des_.replaceAll('\n', '<br />')

    // Build the product object
    let ob__ = chatAI_ob.buildProd({
      "title": (item__.title ? item__.title : props.data.sp_none[props.lang]),
      "sku": (item__.sku ? item__.sku : props.data.sp_none[props.lang]),
      "option1": (item__.brand ? item__.brand : props.data.sp_none[props.lang]),
      "option2": (item__.shopLocation ? item__.shopLocation : props.data.sp_none[props.lang]),
      "description": (item__.description ? item__.description : props.data.sp_none[props.lang]),
      "img_main": (item__.img ? item__.img : props.data.sp_none[props.lang]),
      "img": (item__.img ? item__.img : props.data.sp_none[props.lang])
    });

    // gọi hàm handleCpSale1 để cập nhật thông tin sản phẩm
    props.handleCpSale1(props.sp_id, ob__);
  };

  const tikiLink = (txt_) => {
    try {
      //  Tách các thẻ <script> có type="application/ld+json"
      const jsonScripts = txt_.match(/<script type="application\/ld\+json">([^]*?)<\/script>/g);
      console.log("JSON Scripts:", jsonScripts);

      if (jsonScripts === null) {
        setSpError(props.data.sp_error_bad_request[props.lang]);
        return;
      }

      // Duyệt qua tất cả các thẻ và lọc dựa trên điều kiện @type là "Product"
      for (const script of jsonScripts) {
        // Lấy nội dung JSON từ thẻ <script>
        const jsonStringMatch = script.match(/<script type="application\/ld\+json">([^]*?)<\/script>/);
        const jsonString = jsonStringMatch ? jsonStringMatch[1] : '';
        console.log("JSON String:", jsonString);

        try {
          // Phân tích nội dung JSON
          const jsonObject = JSON.parse(jsonString);
          console.log("JSON Object:", jsonObject);

          // Kiểm tra điều kiện @type và lọc sản phẩm
          if (Array.isArray(jsonObject['@graph'])) {
            for (const item of jsonObject['@graph']) {
              if (item['@type'] === 'Product') {
                // console.log("Product Item:", item);

                // Xử lý dữ liệu sản phẩm
                setSpTitle(item.name || '');
                setSpDes(item.description ? item.description.replaceAll('\n', '<br />') : '');
                setSpImg(item.image ? item.image.url : '');
                setSpSKU(item.sku || ''); // Lấy SKU
                setSpBrand(item.brand ? item.brand.name : '');

                let ob__ = chatAI_ob.buildProd({
                  "title": item.name || props.data.sp_none[props.lang],
                  "description": item.description || props.data.sp_none[props.lang],
                  "img_main": item.image ? item.image.url : props.data.sp_none[props.lang],
                  "img": item.image ? item.image.url : props.data.sp_none[props.lang],
                  "sku": item.sku || props.data.sp_none[props.lang],
                  "brand": item.brand ? item.brand.name : props.data.sp_none[props.lang],
                });

                props.handleCpSale1(props.sp_id, ob__);
                return;
              }
            }
          } else if (jsonObject['@type'] === 'Product') {
            console.log("Single Product Object:", jsonObject);


            setSpTitle(jsonObject.name || '');
            setSpDes(jsonObject.description ? jsonObject.description.replaceAll('\n', '<br />') : '');
            setSpImg(jsonObject.image ? jsonObject.image.url : '');
            setSpSKU(jsonObject.sku || ''); // Lấy SKU
            setSpBrand(jsonObject.brand ? jsonObject.brand.name : ''); // Lấy Brand

            let ob__ = chatAI_ob.buildProd({
              "title": jsonObject.name || props.data.sp_none[props.lang],
              "description": jsonObject.description || props.data.sp_none[props.lang],
              "img_main": jsonObject.image ? jsonObject.image.url : props.data.sp_none[props.lang],
              "img": jsonObject.image ? jsonObject.image.url : props.data.sp_none[props.lang],
              "sku": jsonObject.sku || props.data.sp_none[props.lang],
              "brand": jsonObject.brand ? jsonObject.brand.name : props.data.sp_none[props.lang],
            });

            props.handleCpSale1(props.sp_id, ob__);
            return; // Nếu đã tìm thấy và xử lý, thì không cần tiếp tục
          }
        } catch (e) {
          setSpError(props.data.sp_error_bad_request[props.lang]);
        }
      }
    } catch (e) {
      setSpError(props.data.sp_error_bad_request[props.lang]);
    }
  };
  const shopeeLink = (txt_) => {
    //các bước cắt để lấy ra data json phù hợp -> có title sản phẩm
    //1 cắt `script type="text/mfe-initial-data" data-module=`
    const strp_p_1 = txt_.split(
      `script type="text/mfe-initial-data" data-module=`
    );
    if (strp_p_1 === undefined) {
      setSpError(props.data.sp_error_bad_request[props.lang]);
      return;
    }
    console.log("==strp_p_1===>", strp_p_1);

    //2 tìm đến dấu `{` gần nhất
    if (strp_p_1.length <= 1) {
      setSpError(props.data.sp_error_bad_request[props.lang]);
      return;
    }

    var strp_p_2 = strp_p_1[1].split(`{`);
    strp_p_2.splice(0, 1);

    var strp_p_2_new = "{" + strp_p_2.join("{");
    if (strp_p_2_new === "") {
      setSpError(props.data.sp_error_bad_request[props.lang]);
      return;
    }
    console.log("==strp_p_2_new===>", strp_p_2_new);
    //3 cắt </script> gần nhất
    var strp_p_3 = strp_p_2_new.split(`</script>`);
    if (strp_p_3.length <= 0) {
      setSpError(props.data.sp_error_bad_request[props.lang]);
      return;
    }
    console.log("==strp_p_3===>", strp_p_3);
    var json__ = JSON.parse(strp_p_3[0]);

    var shepee_itemId =
      json__["initialState"]["DOMAIN_CONTEXT"]["data"]["itemId"];
    if (shepee_itemId.length <= 0) {
      setSpError(props.data.sp_error_bad_request[props.lang]);
      return;
    }

    var item__ = json__["initialState"]["item"]["items"][shepee_itemId];

    // console.log("==item__===>", item__);
    // nếu có lỗi thì return và thông báo lỗi
    //không chạy hết hàm
    setSpSKU("" + shepee_itemId);
    setSpError("");
    setSpTitle(item__.name);
    setSpBrand(item__.brand);
    setSpShopLocation(item__.shop_location);
    setSpDes(item__.description.replaceAll("\n", "<br />"));
    setSpImg("https://down-vn.img.susercontent.com/file/" + item__.image);

    let ob__ = chatAI_ob.buildProd({
      title: item__.name ? item__.name : props.data.sp_none[props.lang],
      sku:
        shepee_itemId !== "" ? shepee_itemId : props.data.sp_none[props.lang],
      option1: item__.sp_brand
        ? item__.sp_brand
        : props.data.sp_none[props.lang],
      option2: item__.shop_location
        ? item__.shop_location
        : props.data.sp_none[props.lang],
      description: item__.description
        ? item__.description
        : props.data.sp_none[props.lang],
      img_main: item__.image
        ? " https://down-vn.img.susercontent.com/file/" + item__.image
        : props.data.sp_none[props.lang],
    });
    props.handleCpSale1(props.sp_id, ob__);
  };
  useEffect(() => { });

  return (
    <div
      className="w-full lg:w-1 px-3 mb-4 lg:mb-0"
      style={{ display: props.isDisplay ? "none" : "" }}
    >
      <span className="mr-4">{props.data.sp_support[props.lang]}</span> <br />
      <span className="mr-4">{props.data.sp_support1[props.lang]}</span>
      <div className="mb-4">
        <label className="flex px-2 bg-blueGray-50 rounded">
          <input
            className="w-full p-4 text-xs font-semibold leading-none bg-blueGray-50 rounded outline-none"
            type="text"
            value={spUrl}
            onChange={(e) => setSpUrl(e.target.value)}
          />
          <span className={(spUrlStatus === true ? "bg-blue-500 hover:bg-blue-600" : "bg-blueGray-500 hover:bg-blueGray-600") + " my-1 ml-auto px-4 py-3 text-xs text-white font-semibold leading-none rounded cursor-pointer"} onClick={handlegetUrlMaster}>{props.data.sp_btn[props.lang]}</span>
        </label>
        {spError && spError.length > 0 && (
          <span style={{ color: "#ff000096" }}>{spError}</span>
        )}
        {spImg && spImg.length > 0 && (
          <React.Fragment>
            <img src={spImg} style={{ height: "150px" }} alt=""></img>
            <br />
          </React.Fragment>
        )}
        {spTitle && spTitle.length > 0 && (
          <React.Fragment>
            <span style={{ fontWeight: "bold" }}>
              {props.data.sp_title[props.lang]}
              {spTitle}
            </span>
            <br />
          </React.Fragment>
        )}
        {spBrand && spBrand.length > 0 && (
          <React.Fragment>
            <span>
              {props.data.sp_brand[props.lang]}
              {spBrand}
            </span>
            <br />
          </React.Fragment>
        )}
        {spSKU && spSKU.length > 0 && (
          <React.Fragment>
            <span>
              {props.data.sp_sku[props.lang]}
              {spSKU}111
            </span>
            <br />
          </React.Fragment>
        )}
        {spShopLocation && spShopLocation.length > 0 && (
          <React.Fragment>
            <span>
              {props.data.sp_shop_location[props.lang]}
              {spShopLocation}
            </span>
            <br />
          </React.Fragment>
        )}
        {spDes && spDes.length > 0 && (
          <span
            dangerouslySetInnerHTML={{
              __html: props.data.sp_des[props.lang] + spDes,
            }}
          ></span>
        )}
      </div>
    </div>
  );
}

export default CpDisposableItemsView;
