//file này không được chỉnh sửa hoặc thêm
//chỉ ThanhLM được thêm
export default class chatAI {
    constructor() {
        this.fromTo = "Ok";
    }
    test(params) {
        console.log("a" + params);
    }

    buildProd(data_request) {
        //tự bổ sung các trường còn thiếu, và có mặc định
        //đảm bảo mã nguồn gói tin luôn được cập nhật mới
        let data = {
            title: "",
            sku: "",
            img_main: "",
            price: 0,
            color: "",
            size: "",
            material: "",
            inventory: 0,
            link_web: "",
            shopee_id: "",
            img: "",
            action1: "",
            action2: "",
            action3: "",
            action4: "",
            action5: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
            status: "A",
            description: "",
            info_web: ""
        }
        for (let i in data_request) {
            data[i] = data_request[i];
        }
        // console.log("chatAI buildProd data===>", data);
        return data;
    }
}