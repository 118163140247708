// import { NavLink } from 'react-router-dom';
import React, { useEffect } from 'react';

const Cp_Header = (props) => {

    useEffect(() => {
    }, []);

    return (
        <header className="bg-transparent sticky-bar mt-4">
            <div className="container bg-transparent">
                <nav className="bg-transparent flex justify-between items-center py-3">
                    <a className="text-3xl font-semibold leading-none" href="/">
                        <img className="h-10" src="/assets/imgs/logos/Handsup.svg" alt="" />
                    </a>
                    <ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
                        {props.data_master.menu.header.map((item, i) => item.childs.length === 0 ? <li key={i} className="pt-4 pb-4">
                            <a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500" href={item.link[props.lang].includes("http") === true ? item.link[props.lang] : "/s" + item.link[props.lang]}>{item.title[props.lang]}</a>
                        </li> : <li key={i} className="group relative pt-4 pb-4 has-child">
                            <a className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500" href={item.link[props.lang].includes("http") === true ? item.link[props.lang] : "/s" + item.link[props.lang]}>{item.title[props.lang]}</a>
                            <ul className="drop-down-menu min-w-200">
                                {item.childs.map((item_child, i_c) => <li key={i_c}>
                                    <a className="menu-sub-item text-sm text-blueGray-600 hover:text-blueGray-500" href={item_child.link[props.lang].includes("http") === true ? item_child.link[props.lang] : "/s" + item_child.link[props.lang]}>{item_child.title[props.lang]}</a>
                                </li>)}
                            </ul>
                        </li>)}
                    </ul>
                    <div className="hidden lg:block">
                        {props.data_master["list-lang"].map((item, i) => item.code !== props.lang && <a key={i} className="btn-accent hover-up-2" href={"/s/" + item.code}>{item.title}</a>)}
                        {/* <a className="btn-accent hover-up-2" href="login.html">Log In</a>
                        <a className="btn-primary hover-up-2" href="signup.html">Sign Up</a> */}
                    </div>
                    <div className="lg:hidden">
                        <button onClick={(e) => { window.mobileMenu(); }} className="navbar-burger flex items-center py-2 px-3 text-blue-500 hover:text-blue-700 rounded border border-blue-200 hover:border-blue-300">
                            <svg className="fill-current h-4 w-4" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <title>Mobile menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg>
                        </button>
                    </div>
                </nav>
                {/* <!--Mobile menu--> */}
                <div className="hidden navbar-menu relative z-50 transition duration-300">
                    <div className="navbar-backdrop fixed inset-0 bg-blueGray-800 opacity-25"></div>
                    <nav className="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white border-r overflow-y-auto transition duration-300">
                        <div className="flex items-center mb-8">
                            <a className="text-3xl font-semibold leading-none" href="/">
                                <img className="h-10" src="/assets/imgs/logos/Handsup.svg" alt="" />
                            </a>
                            <button onClick={(e) => { window.mobileMenu(); }} className="navbar-close absolute top-0 right-0 mt-4 mr-4">
                                <svg className="h-6 w-6 text-blueGray-400 cursor-pointer hover:text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>
                        <div>
                            <ul className="mobile-menu">
                                {props.data_master.menu.header.map((item, i) => item.childs.length === 0 && <li key={i} className="mb-1">
                                    <a className="block p-4 text-sm text-blueGray-500 hover:bg-blue-50 hover:text-blue-500 rounded-xl" href={item.link[props.lang].includes("http") === true ? item.link[props.lang] : "/s" + item.link[props.lang]}>{item.title[props.lang]}</a>
                                </li>)}
                            </ul>
                        </div>
                        <div className="mt-auto mb-5">
                            <h2 className="mb-4 text-lg font-heading text-center">
                                {(props.lang && props.data_master?.footer?.['follow-us']?.[props.lang]) || 'Follow us'}
                            </h2>
                            <ul className="flex flex-row flex-wrap justify-center lg:justify-start">
                                <li>
                                    <a className="inline-block px-1" href="https://www.facebook.com/handsupchat" target="_blank" rel="noopener noreferrer">
                                        <img src="/assets/imgs/icons/facebook-blue.svg" className="w-6 h-6" alt="Facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a className="inline-block px-1" href="https://www.instagram.com/handsup_chat?igsh=ZG5vYW5vNHVkYnZp" target="_blank" rel="noopener noreferrer">
                                        <img src="/assets/imgs/icons/instagram-blue.svg" className="w-6 h-6" alt="Instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a className="inline-block px-1" href="https://x.com/Handsupchat" target="_blank" rel="noopener noreferrer">
                                        <img src="/assets/imgs/icons/twitter_x.svg" className="w-6 h-6" alt="Twitter X" />
                                    </a>
                                </li>
                                <li>
                                    <a className="inline-block px-1" href="https://www.threads.net/@handsup_chat" target="_blank" rel="noopener noreferrer">
                                        <img src="/assets/imgs/icons/Threads.svg" className="w-6 h-6" alt="Threads" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default Cp_Header;