import React, { useEffect } from 'react';
// import CpMenu from "../component/cp_menu"
// import CpCategories from "../component/cp_categories"
// import CpTodaysDeal from "../component/cp_todays_deal"
// import CpTopSale from "../component/cp_top_sale"
// import CpGroupSale from "../component/cp_group_sale"
// import CpFooter from "../component/cp_footer"
// import Markdown from 'react-markdown'
// import CpLogo from "../component/cp_logo"
// import CpBanner from "../component/cp_banner"
// import CpContentTop from "../component/cp_content_top"
// import CpContentNumber from "../component/cp_content_number"
// import CpContentProd from "../component/cp_content_prod"
// // import CpContentFunc from "../component/cp_content_func"
// import CpContentGallery from "../component/cp_content_gallery"
// // import CpContentComment from "../component/cp_content_comment"
// // import CpContentPartner from "../component/cp_content_partner"
// // import CpModels from "../component/cp_models"
// // import CpContact from "../component/cp_contact"
// import CpHeader from "../component/cp_header"
// import CpFooter from "../component/cp_footer"
// import CpCompany from "../component/cp_company"
// import $ from "jquery"; 
import CpLoading from "../component/cp_loading"

function P404(props) {
    useEffect(() => {
        document.title = ``;
        window.abcC();
    }, []);

    return (<React.Fragment>
        <div className="main">
            <section className="pt-20 pb-32">
                <div className="container text-center">
                    <img className="mb-4 mx-auto md:max-w-md mb-12 wow animate__animated animate__fadeIn" src="/assets/imgs/illustrations/error2.png" alt="" />
                    <span className="text-4xl text-blue-500 font-bold font-heading wow animate__animated animate__fadeIn" data-wow-delay=".2s">Error 404</span>
                    <h2 className="mb-2 text-4xl font-bold font-heading wow animate__animated animate__fadeIn" data-wow-delay=".3s">Something went wrong!</h2>
                    <p className="mb-6 text-blueGray-400 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">Sorry, but we are unable to open this page.</p>
                    <div>
                        <a className="block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded wow animate__animated animate__fadeInUp" data-wow-delay=".5s" href="/">Go back to Homepage</a>
                        <a className="block sm:inline-block py-4 px-8 text-xs text-blueGray-500 hover:text-blueGray-800 text-center font-semibold leading-none bg-blueGray-50 hover:bg-blueGray-100 rounded wow animate__animated animate__fadeInUp" data-wow-delay=".6s" href="contact.html">Contact Us</a>
                    </div>
                </div>
            </section>
        </div>

        <CpLoading {...props}></CpLoading>
    </React.Fragment >
    )
};

export default P404;