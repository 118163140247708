// import { NavLink } from 'react-router-dom';
import React from 'react';
const cp_user_guide = (props) => (
    <div className="w-full md:w-1/2 lg:w-1/4 px-3 mb-6">
        <div className="border border-gray-100 pt-8 px-6 pb-6 bg-white text-center rounded shadow hover:shadow-lg hover-up-2 transition duration-500 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
            <div className="text-blue-500 mx-auto mb-4">
                {props.img}
            </div>
            <h3 className="mb-2 font-bold font-heading">{props.title}</h3>
            <p className="text-sm text-blueGray-400" style={{ "text-align": "justify" }}>{props.des}</p>
        </div>
    </div>
);

export default cp_user_guide;