// import { NavLink } from 'react-router-dom'; 
import React, { useEffect } from 'react';

function CpDefault(props) {
    useEffect(() => {
    });
    // // Xử lý nhập text trong bước 2
    // let str_inputInforAI = inputInfoAI.trim();
    // setInforAlErrors("");
    // if (str_inputInforAI.length < 300 || str_inputInforAI.length > 1000) {
    //     //hiện thông báo cáo lỗi 
    //     setInforAlErrors(data.inputInfoAIError1[props.lang]);
    // }
    // else
    //     setInforAlErrors("");

    // // Xử lý phần xác nhận thông tin trong bước 2
    // let isAccept = isTermsAccepted;
    // setIsTermsAcceptedError("");
    // if (isAccept === false) {
    //     setIsTermsAcceptedError(data.isTermsAcceptedError1[props.lang]);
    // } else
    //     setIsTermsAccepted("")

    // // const [inputInforStatus, setInputInforStatus] = useState(false);
    // // // Khởi tạo trạng thái đã xác nhận chọn sản phẩm 1

    // const [inputSP1Status, setInputSP1Status] = useState(false);
    // // // Khởi tạo trạng thái đã xác nhận chọn sản phẩm 2
    // const [inputSP2Status, setInputSP2Status] = useState(false);
    // // // Khởi tạo trạng thái đã xác nhận chọn sản phẩm 3
    // const [inputSP3Status, setInputSP3Status] = useState(false);

    return <div className="flex flex-wrap mb-4 -mx-3 wow animate__animated animate__fadeInUp animated" data-wow-delay=".3s">
        <div className="w-full lg:w-1/2 px-3 mb-4 lg:mb-0">
            <div className="mb-4">
                <span className="my-1 ml-auto px-4 py-3 text-xs text-white font-semibold leading-none bg-blueGray-500 hover:bg-blueGray-600 rounded">{props.data.sp_comming_soon[props.lang]}</span>
            </div>
        </div>
    </div>
};

export default CpDefault;