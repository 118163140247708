import React, { useEffect } from 'react';

function MyChat(props) {
    useEffect(() => {
    }, []);

    return (
        <div id="your-chat" className="your-chat">
             <span dangerouslySetInnerHTML={{ __html: props.data_content }} />
            {props.data_content && <p className="your-chat-balloon">{props.data_content.content}</p>}
            {props.data_content &&<p className="chat-datetime">
                <span className="glyphicon glyphicon-ok"></span>
                {props.data_content.time}
            </p>}
        </div>)
};

export default MyChat;