import chatAI from "../chat_ai/obChatAI";
var chatAI_ob = new chatAI();
//hỗ trợ xử lý dữ liệu ở các sàn thương mại và đưa về cấu trúc data yêu cầu
export default class obViewProd {
    constructor() {
        this.fromTo = "Ok";
    }
    lazada_vn_link(txt_, setSpError, props) {
        //các bước cắt để lấy ra data json phù hợp -> có title sản phẩm 
        //1 cắt `script type="script type="application/ld+json` 
        const strp_lazada = txt_.split(`script type="application/ld+json`)
        //chuẩn hóa chuỗi 
        const jsonString = strp_lazada[1].split('</script>')[0].trim();
        const json = jsonString.replace(/^\s*"\s*>/, '').trim();
        //chuyển về json
        const jsonObject = JSON.parse(json);
        // console.log(jsonObject);
        //lấy các trường cần thiết trong json
        const title = jsonObject.name;
        const sku = jsonObject.sku;
        const img = jsonObject.image;
        const description = jsonObject.description;
        const brand = jsonObject.brand.name;
        const shopLocation = jsonObject.brand.url;

        let ob__ = chatAI_ob.buildProd({
            "title": (title ? title : props.data.sp_none[props.lang]),
            "sku": (sku ? sku : props.data.sp_none[props.lang]),
            "option1": (brand ? brand : props.data.sp_none[props.lang]),
            "option2": (shopLocation ? shopLocation : props.data.sp_none[props.lang]),
            "description": (description ? description : props.data.sp_none[props.lang]),
            "img_main": (img ? img : props.data.sp_none[props.lang]),
            "img": (img ? img : props.data.sp_none[props.lang])
        });
        return ob__;
    }
    amazon_com_link(txt_, setSpError, props) {
        /*
                Tạm thời đang lấy ổn định
                Title: Lấy được title trên nhiều sản phẩm khác nhau
                Brand: Lấy được brand trên nhiều sản phẩm khác nhau
                SKU: Lấy được SKU trên nhiều sản phẩm khác nhau
                Shop location: Amazon không cung cấp thông tin vị trí cửa hàng
                
                Description: Chưa hoàn thành (Mỗi trang Amazon có cấu trúc khác nhau, nên cần phải xác định cấu trúc trang hiện tại để lấy thông tin mô tả sản phẩm)
        
                Image URL: Lấy được URL ảnh trên nhiều sản phẩm khác nhau
                */

        /*
        1/ hoàn thành việc khai báo và xử lý ở 1 vùng: vùng trên cùng là khai báo biến và xử lý chặn (có mô tả biến và lý do), vùng xử lý logic (có mô tả biến và lý do)
        2/ try và gửi lỗi logic đến 1 kênh chung là telegram mặc định là thanhlm22 hoặc mã id -4566353440
        */

        //1. Title
        // Xây dựng hàm decodeHtmlCharCodes để giải mã các ký tự HTML
        // VD: Ký tự: &#39; -> '
        function decodeHtmlCharCodes(str) {
            return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
                return String.fromCharCode(charCode);
            });
        }

        // <span id="productTitle" class="a-size-large product-title-word-break">Amazon Essentials Women&#39;s Classic-Fit Short-Sleeve V-Neck T-Shirt, Multipacks</span>
        // Lấy ra title của sản phẩm bằng cách cắt chuỗi theo điều kiện
        // <span id="productTitle" class=".*?">(.*?)<\/span> 
        // Trong đó:
        // - <span id="productTitle" class=".*?">: chuỗi bắt đầu
        // - (.*?): nội dung cần lấy
        // - <\/span>: chuỗi kết thúc             
        const titleMatch = txt_.match(/<span id="productTitle" class=".*?">(.*?)<\/span>/);
        if (titleMatch === undefined) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }
        // .trim() bỏ dấu cách ở đầu và cuối chuỗi
        const title = decodeHtmlCharCodes(titleMatch ? titleMatch[1].trim() : "");

        // 2. SKU (ASIN for Amazon)
        // "asin" ?: ?"(\w+)"
        // Trong đó:
        // - "asin" ?: ?: chuỗi bắt đầu
        // - "(\w+)": nội dung cần lấy
        // - \w: ký tự chữ cái hoặc số
        // - +: 1 hoặc nhiều lần
        const skuMatch = txt_.match(/"asin" ?: ?"(\w+)"/);
        if (skuMatch === undefined) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return;
        }

        const sku = skuMatch ? skuMatch[1] : "";

        // 3. brand
        // <a id="bylineInfo" class=".*?">(.*?)<\/a>
        // Trong đó:
        // - <a id="bylineInfo" class=".*?">: chuỗi bắt đầu
        // - (.*?): nội dung cần lấy
        // - <\/a>: chuỗi kết thúc
        const brandMatch = txt_.match(/<a id="bylineInfo" class=".*?">(.*?)<\/a>/);
        const brand = brandMatch ? brandMatch[1].trim() : "";

        // 4. shop location
        // Vì amazon không cung cấp thông tin vị trí cửa hàng
        const shopLocation = "";

        // 5. description
        const descMatch = txt_.matchAll(/span class="a-list-item a-size-base a-color-base.*?">(.*?)<\/span>/g);
        const desc = []

        // const description = descMatch ? descMatch[1] : "";
        for (const match of descMatch)
            desc.push(match[1])
        // const description = desc.join('\n')

        //Paser text to element: // document.getElementById('feature-bullets').textContent
        const parser = new DOMParser();
        const doc = parser.parseFromString(txt_, 'text/html');
        const featureBullets = doc.getElementById('feature-bullets');

        // Lấy nội dung từ phần tử 'feature-bullets' nếu có
        let description = '';

        // Kiểm tra xem featureBullets có tồn tại hay không trước khi thao tác
        if (featureBullets && featureBullets.querySelectorAll) {
            const listItems = featureBullets.querySelectorAll('li');

            // Kiểm tra nếu listItems có tồn tại và có độ dài hợp lệ
            if (listItems.length > 0) {
                description = [...listItems].map(item => item.innerText.trim()).join('\n');
            }
        }

        // Nếu không có featureBullets hoặc không có các li, sử dụng desc fallback từ regex
        if (!description) {
            description = desc.join('\n') || '';
        }


        // 6. image URL
        // <script type="a-state" data-a-state="{&quot;key&quot;:&quot;desktop-landing-image-data&quot;}">{"landingImageUrl":"https://m.media-amazon.com/images/I/61VXOrqsTyS.__AC_SY445_SX342_QL70_FMwebp_.jpg"}</script>
        // Lấy ra URL ảnh của sản phẩm bằng cách căt chuỗi theo điều kiện
        // "landingImageUrl":"(.*?)"
        // Trong đó:
        // - "landingImageUrl": chuỗi bắt đầu
        // - (.*?): nội dung cần lấy
        const imgMatch = txt_.match(/"landingImageUrl":"(.*?)"/);
        const img = imgMatch ? imgMatch[1] : "";

        return {
            sku: sku,
            title: title,
            brand: brand,
            shopLocation: shopLocation,
            description: description,
            img: img
        }
    }
    tiki_vn_link(txt_, setSpError, props) {
        //  Tách các thẻ <script> có type="application/ld+json"
        const jsonScripts = txt_.match(/<script type="application\/ld\+json">([^]*?)<\/script>/g);

        if (jsonScripts === null)
            return null;

        // Duyệt qua tất cả các thẻ và lọc dựa trên điều kiện @type là "Product"
        for (const script of jsonScripts) {
            // Lấy nội dung JSON từ thẻ <script>
            const jsonStringMatch = script.match(/<script type="application\/ld\+json">([^]*?)<\/script>/);
            const jsonString = jsonStringMatch ? jsonStringMatch[1] : '';
            try {
                // Phân tích nội dung JSON
                const jsonObject = JSON.parse(jsonString);

                // Kiểm tra điều kiện @type và lọc sản phẩm
                if (Array.isArray(jsonObject['@graph'])) {
                    for (const item of jsonObject['@graph']) {
                        if (item['@type'] === 'Product') {
                            let ob__ = chatAI_ob.buildProd({
                                "title": item.name || props.data.sp_none[props.lang],
                                "description": item.description || props.data.sp_none[props.lang],
                                "img_main": item.image ? item.image.url : props.data.sp_none[props.lang],
                                "img": item.image ? item.image.url : props.data.sp_none[props.lang],
                                "sku": item.sku || props.data.sp_none[props.lang],
                                "brand": item.brand ? item.brand.name : props.data.sp_none[props.lang],
                            });
                            return ob__;
                        }
                    }
                } else if (jsonObject['@type'] === 'Product') {
                    let ob__ = chatAI_ob.buildProd({
                        "title": jsonObject.name || props.data.sp_none[props.lang],
                        "description": jsonObject.description || props.data.sp_none[props.lang],
                        "img_main": jsonObject.image ? jsonObject.image.url : props.data.sp_none[props.lang],
                        "img": jsonObject.image ? jsonObject.image.url : props.data.sp_none[props.lang],
                        "sku": jsonObject.sku || props.data.sp_none[props.lang],
                        "brand": jsonObject.brand ? jsonObject.brand.name : props.data.sp_none[props.lang],
                    });
                    return ob__;
                }
            } catch (e) {
                setSpError(props.data.sp_error_bad_request[props.lang]);
            }
        }
        return null;
    }
    shopee_vn_link(txt_, setSpError, props) {
        //các bước cắt để lấy ra data json phù hợp -> có title sản phẩm 
        //1 cắt `script type="text/mfe-initial-data" data-module=`
        const strp_p_1 = txt_.split(`script type="text/mfe-initial-data" data-module=`)
        if (strp_p_1 === undefined) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return null;
        }

        //2 tìm đến dấu `{` gần nhất
        if (strp_p_1.length <= 1) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return null;
        }

        var strp_p_2 = strp_p_1[1].split(`{`)
        strp_p_2.splice(0, 1);

        var strp_p_2_new = "{" + strp_p_2.join('{')
        if (strp_p_2_new === "") {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return null;
        }
        //3 cắt </script> gần nhất
        var strp_p_3 = strp_p_2_new.split(`</script>`)
        if (strp_p_3.length <= 0) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return null;
        }

        var json__ = JSON.parse(strp_p_3[0]);

        var shepee_itemId = json__["initialState"]["DOMAIN_CONTEXT"]["data"]["itemId"];
        if (shepee_itemId.length <= 0) {
            setSpError(props.data.sp_error_bad_request[props.lang]);
            return null;
        }
        let json__new = json__["initialState"]["item"]["items"][shepee_itemId];

        let ob__ = chatAI_ob.buildProd({
            "title": (json__new.name ? json__new.name : props.data.sp_none[props.lang]),
            "sku": (shepee_itemId !== "" ? shepee_itemId : props.data.sp_none[props.lang]),
            "option1": (json__new.sp_brand ? json__new.sp_brand : props.data.sp_none[props.lang]),
            "option2": (json__new.shop_location ? json__new.shop_location : props.data.sp_none[props.lang]),
            "description": (json__new.description ? json__new.description : props.data.sp_none[props.lang]),
            "img_main": (json__new.image ? " https://down-vn.img.susercontent.com/file/" + json__new.image : props.data.sp_none[props.lang]),
            "img": (json__new.image ? " https://down-vn.img.susercontent.com/file/" + json__new.image : props.data.sp_none[props.lang])
        });
        return ob__;
    }
}