import React, { useEffect, useState } from 'react';
import CpHeader from "../component/cp_header"
import CpFooter from "../component/cp_footer"
import CpLoading from "../component/cp_loading"

import CpSale1 from "../component/chat_ai/cp_sale1"
import CpResale from "../component/chat_ai/cp_resale"
import CpDisposableItems from "../component/chat_ai/cp_disposable_items"
import CpDefault from "../component/chat_ai/cp_default"
import CpHousehold_Appliances from "../component/chat_ai/cp_household_appliances"

import { loadContent } from "../lib/libFly";
// import chatAI from "../component/chat_ai/obChatAI"
import Markdown from 'react-markdown';
var data_spAI = {};
var data_inputInfoAI = "";

//COD
var data_inputInfoCOD = "";

// var chatAI_ob = new chatAI();
function Phome(props) {
    const [data, setData] = useState();

    //bước 1 ------------------------------------------
    const [inputPhone, setInputPhone] = useState("");
    const [inputPhoneError, setInputPhoneError] = useState("");
    const [inputPhoneBtnCount, setInputPhoneBtnCount] = useState(0);

    const const_inputOTP = props.isDev ? "12345" : ""
    const const_inputOTPStatus = props.isDev ? true : false
    const [inputOTP, setInputOTP] = useState(const_inputOTP);
    //cờ cho OPT đã đạt giúp vô hiện inputPhone
    const [inputOTPStatus, setInputOTPStatus] = useState(const_inputOTPStatus);
    const [inputOTPError, setInputOTPError] = useState("");
    const [codeServer, setCodeServer] = useState(const_inputOTP);

    // bước 2 ------------------------------------------   
    const [selectedDepartment, setSelectedDepartment] = useState('sale1');
    const listDepartment = {
        "hotel": CpDefault,
        "resale": CpResale,
        "disposable_items": CpDisposableItems,
        "household_appliances": CpHousehold_Appliances,
        "sale1": CpSale1
    };
    const [step2_SP_Status, setStep2_SP_Status] = useState(false);

    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    // chuyển về onchainge
    // const handleTermsChange = (event) => { setIsTermsAccepted(event.target.checked); };
    // In ra lỗi nếu không xác nhận thông tin
    const [isTermsAcceptedError, setIsTermsAcceptedError] = useState("");

    //bước 3 ------------------------------------------
    const [inputFB] = useState("https://www.facebook.com/messages/t/106651135720214");

    const [inputIns] = useState("https://www.instagram.com/direct/t/17846417550319699");

    const [inputSP] = useState("https://shopee.vn/katuni.store");

    const [inputZL] = useState("https://handsupchat.com/assets/imgs/zalo-private.png");

    const [inputZLOA] = useState("https://handsupchat.com/assets/imgs/detail-qr-zalo-oa-app98.png");

    const handleCheckOPT = () => {
        if (data === undefined)
            return;

        //đã xác nhận OTP thì dừng
        if (inputOTPStatus === true)
            return;

        let str_inputOTP = inputOTP.trim();
        /*
            4/ Xác nhận OTP: OTP  không trống, không chữ, 
            -> chỉ bằng 5 ký tự (bỏ)
            -> có *
            -> có so sánh với mã server trả về -> có code xong rồi -> hiện thông báo khi mã giống
        */
        setInputOTPError("");
        if (str_inputOTP.length > 0 && str_inputOTP === codeServer) {
            //hiện thông báo cáo lỗi 
            setInputOTPStatus(true);
        }
        else
            setInputOTPError(data.inputOTPError1[props.lang])
    }
    const call_CpSale = (spAI) => {
        data_spAI = spAI;
    }
    const call_CpSale_status = (statuSP) => {
        setStep2_SP_Status(statuSP);
    }
    const call_InputInfo = (txt_) => {
        data_inputInfoAI = txt_;
    }
    const call_InputInfoCOD = (txt_) => {
        data_inputInfoCOD = txt_;
    }

    const handleSendAuthenticate = () => {
        if (data === undefined)
            return;

        if (inputPhoneBtnCount > 0)
            return;

        //đã xác nhận OTP thì dừng
        if (inputOTPStatus === true)
            return;
        let flag_error = false;

        //kiểm tra inputPhone
        /*
            1/ Số điện thoại của bạn: không trống, không chữ, mặc định là +84 số 0-9
            -> ko có ký tự ngoài số (chỉ từ 0->9) ko có +84 -> mình sẽ có mục này
            -> có *
            -> dài 9 or 10 ký tự
        */
        let str_inputPhone = inputPhone.trim();
        if (str_inputPhone.length < 3) {
            //hiện thông báo cáo lỗi 
            setInputPhoneError(data.inputPhoneError1[props.lang])
            flag_error = true;
        }

        var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!filter.test(str_inputPhone)) {
            setInputPhoneError(data.inputPhoneError3[props.lang])
            flag_error = true;
        }

        if (flag_error === false) {
            setInputPhoneError("");
            /*
                2/ Button Số diện thoại gửi
                -> hiện lỗi 1/ không có thì gọi API SBAuthenticate
                -> vô hiệu hoá button này
                -> trong 120 không được gửi lại -> có đếm giảm ở button trong nội dung button
            */
            //vòng chờ để tránh bị spam SMS
            var inputPhoneBtnCount_int = 120;
            setInputPhoneBtnCount(inputPhoneBtnCount_int);
            var stI = setInterval(() => {
                inputPhoneBtnCount_int = inputPhoneBtnCount_int - 1
                setInputPhoneBtnCount(inputPhoneBtnCount_int);
                if (inputPhoneBtnCount_int <= 0)
                    clearInterval(stI);
            }, 1000);

            fetch("https://handsupchat.com/pyform-data", {
                method: "POST",
                body: JSON.stringify({
                    "key__request": "SBAuthenticate",
                    "data__request": {
                        "inputPhone": inputPhone,
                        "prefixPhone": "84",
                        "html_email_title": data.html_email_title[props.lang],
                        "html_email_content": data.html_email_content[props.lang],
                    }
                }),
                headers: {
                    "GooPyForm": "IsGOO",
                    "Accept": "text/plain",
                    "Content-Type": "application/json"
                }
            }).then((response) => response.json()).then((json) => {
                if (json.err === "00" && json.messOk === "readOk" && json.data !== undefined)
                    setCodeServer(json.data)
            });
        }
    };

    const handleSendText = () => {
        if (data === undefined)
            return;
        if (isTermsAccepted === false) {
            alert(data.isTermsAcceptedError1[props.lang]);
            return;
        }

        if (step2_SP_Status === false) {
            return;
        }
        if (data_inputInfoAI === false) {
            return;
        }
        setIsTermsAcceptedError("");

        if (data_inputInfoAI === "") {
            alert(data.form_gioithieu_empty_error[props.lang]);
            return;
        }
        if (data_inputInfoAI.length < 200 || data_inputInfoAI.length > 500) {
            alert(data.form_gioithieu_empty_error[props.lang]);
            return;
        }

        //COD
        if (data_inputInfoCOD === "") {
            alert(data.form_COD_empty_error[props.lang]);
            return;
        }

        let total_AI = data_inputInfoAI + "\n" + data.form_input_2_title2[props.lang] + ":\n" + data_inputInfoCOD;
        // alert("total_AI===>" + total_AI);
        //1/11/2024 
        //ThanhLM bỏ chuyển danh sách sản phẩm nội dung tư vấn
        //trong clsSocBay có AI2 đã phân tích được sản phẩm và SKU theo cấu hình này
        // let index__sp = 1;
        // for (const x in data_spAI) {
        //     let data_spAI_item = data_spAI[x];
        //     let sp_ai_title = data.sp_ai_title[props.lang];
        //     sp_ai_title = sp_ai_title.replace("{1}", "" + index__sp)
        //     total_AI += "\n" + sp_ai_title;
        //     for (const x1 in data_spAI_item) {
        //         if (data_spAI_item[x1] !== undefined && typeof data_spAI_item[x1] === 'string' && data_spAI_item[x1].trim() !== '')
        //             total_AI += "\n" + data_spAI_item[x1];
        //     }
        //     index__sp++;
        // }


        if (inputPhone !== "" && codeServer === inputOTP && codeServer !== "" && inputOTP !== "") {
            fetch("https://handsupchat.com/pyform-data", {
                method: "POST",
                body: JSON.stringify({
                    "key__request": "SBRegister",
                    "data__request": {
                        "inputPhone": inputPhone,
                        "inputOTP": inputOTP,
                        "inputInfoAI": total_AI,
                        //dữ liệu của tất cả cp trong chat_ai được nạp vào
                        ...data_spAI,
                        "selectedDepartment": selectedDepartment,
                        "isTermsAccepted": isTermsAccepted,
                        "inputFB": inputFB,
                        "inputIns": inputIns,
                        "inputSP": inputSP,
                        "inputZL": inputZL,
                        "inputZLOA": inputZLOA
                    }
                }),
                headers: {
                    "GooPyForm": "IsGOO",
                    "Accept": "text/plain",
                    "Content-Type": "application/json"
                }
            }).then((response) => response.json()).then((json) => {
                if (json.err === "00" && json.messOk === "readOk")
                    alert(data.save_form_ok[props.lang]);
            });
        }
        else
            alert(data.otp_not_ok[props.lang]);
    };

    useEffect(() => {
        let aa_ = loadContent("home");
        aa_.then((result) => {
            if (result.title !== undefined && result.title[props.lang] !== undefined)
                document.title = result.title[props.lang];
            setData(result);
        })

        window.abcC();
    }, [props.lang]);

    return (<React.Fragment>
        <div className="main">
            <CpHeader {...props}></CpHeader>
            <section className="xl:bg-contain bg-top bg-no-repeat -mt-24 pt-24" style={{ "backgroundImage": "url('assets/imgs/backgrounds/intersect.svg')" }}>
                <div className="container px-4 mx-auto">
                    <div className="pt-12 text-center">
                        <div className="max-w-2xl mx-auto mb-8">
                            <h4 className="text-xl lg:text-2xl lg:leading-normal mb-4 font-bold font-heading wow animate__animated animate__fadeIn"><Markdown>{data && data.step10[props.lang]}</Markdown> <span className="text-blue-500"><Markdown>{data && data.step11[props.lang]}</Markdown></span></h4>
                            <p className="text-blueGray-400 leading-relaxed wow animate__animated animate__fadeIn">{data && data.step20[props.lang]} <strong className="text-blue-500">{data && data.step21[props.lang]}</strong> {data && data.step22[props.lang]}</p>
                        </div>
                    </div>
                </div>
                <div className="relative max-w-6xl mt-16 md:mt-8 mb-8 mx-auto">
                    <img src="/assets/imgs/elements/pattern.png" alt="" />
                    <div className="absolute" style={{ "top": "9%", "left": "14%", "width": "72%", "height": "66%" }}>
                        <div className="inset-0 absolute">
                            <iframe
                                className="w-full h-full rounded wow animate__animated animate__fadeInUp"
                                src="https://www.youtube.com/embed/5kAbS4PJerw?rel=0&autoplay=1&modestbranding=1&playlist=5kAbS4PJerw&loop=1"
                                title="YouTube video player"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-20 bg-blue-200" id="how-we-work">
                <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-12">
                        <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
                            <h2 className="text-3xl md:text-4xl font-bold font-heading wow animate__animated animate__fadeInDown">
                                <span>{data && data.step30[props.lang]} </span>
                                <span className="text-blue-500">{data && data.step31[props.lang]}</span>
                                <span> {data && data.step32[props.lang]}</span>
                            </h2>
                        </div>
                        <div className="w-full lg:w-1/2 lg:pl-16">
                            <p className="text-black leading-loose wow animate__animated animate__fadeInUp">{data && data.step33[props.lang]}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-20">
                <div className="container">
                    <div className="max-w-2xl lg:max-w-3xl mx-auto">
                        <div className="container">
                            <div className="row min-vh-100 align-items-center">
                                <div className="col-12 text-center">
                                    <div className={"py-3 px-6 text-2xl text-white font-bold uppercase leading-none bg-blue-400 hover:bg-blue-500 rounded"}>
                                        {data && data.form_title_4[props.lang]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-8">
                                <div className="w-full px-12 py-12">
                                    {/* Bước 1 */}
                                    <ul className="space-y-12">
                                        <li className="flex -mx-4 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                                            <div className="px-4">
                                                <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">1</span>
                                            </div>
                                            <div className="px-4">
                                                <h3 className="my-4 text-xl font-semibold">{data && data.rg_1_title[props.lang]}</h3>
                                                <p className="text-blueGray-400 leading-loose">{data && data.rg_1_des[props.lang]}</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <br />
                                    <div className="flex flex-wrap mb-4 -mx-3 wow animate__animated animate__fadeInUp animated" data-wow-delay=".3s">
                                        <div className="w-full lg:w-1/2 px-3 mb-4 lg:mb-0">
                                            <span className="mr-4 font-semibold">{data && data.form_input_1_title[props.lang]}</span>
                                            <div className="mb-4">
                                                <label className="flex px-2 bg-blueGray-50 rounded">
                                                    <input className="w-full p-4 text-xs font-semibold leading-none bg-blueGray-50 rounded outline-none" type="text" value={inputPhone} onChange={e => setInputPhone(e.target.value)} />
                                                    <span className={"my-1 ml-auto px-4 py-3 text-xs text-white font-semibold leading-none " + (inputPhoneBtnCount <= 0 && inputOTPStatus === false ? "bg-blue-500 hover:bg-blue-600" : "bg-blueGray-500 hover:bg-blueGray-600") + " rounded cursor-pointer"} onClick={handleSendAuthenticate} >{data && inputPhoneBtnCount <= 0 && data.form_input_1_btn[props.lang]} {inputPhoneBtnCount > 0 ? inputPhoneBtnCount : ""}</span>
                                                </label>
                                                {inputPhoneError.length > 0 && <span style={{ "color": "#ff000096" }}>{inputPhoneError}</span>}
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-3 mb-4 lg:mb-0">
                                            <span className="mr-4 font-semibold">{data && data.form_input_11_title[props.lang]}</span>
                                            <div className="mb-4">
                                                <label className="flex px-2 bg-blueGray-50 rounded">
                                                    <input className="w-full p-4 text-xs font-semibold leading-none bg-blueGray-50 rounded outline-none" type="text" value={inputOTP} onChange={e => setInputOTP(e.target.value)} />
                                                    <span className={"my-1 ml-auto px-4 py-3 text-xs text-white font-semibold leading-none " + (inputOTP === codeServer && codeServer.length > 0 ? "bg-blue-500 hover:bg-blue-600" : "bg-blueGray-500 hover:bg-blueGray-600") + " rounded cursor-pointer"} onClick={handleCheckOPT} >{data && data.form_input_11_btn[props.lang]}</span>
                                                </label>
                                                {inputOTPError.length > 0 && <span style={{ "color": "#ff000096" }}>{inputOTPError}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Bước 2 */}
                                    <ul className="space-y-12">
                                        <li className="flex -mx-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                            <div className="px-4">
                                                <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">2</span>
                                            </div>
                                            <div className="px-4">
                                                <h3 className="my-4 text-xl font-semibold">{data && data.rg_2_title[props.lang]} {inputOTPStatus === false && data ? "(" + data.form_input_2_title1[props.lang] + ")" : ""}</h3>
                                                <p className="text-blueGray-400 leading-loose">{data && data.rg_2_des[props.lang]}</p>
                                            </div>
                                        </li>
                                    </ul>
                                    <br />
                                    {inputOTPStatus && <div className="mb-4 text-sm wow animate__animated animate__fadeInUp animated" data-wow-delay=".1s">
                                        <span className="mr-4 font-semibold">{data && data.form_input_2_title[props.lang]}:</span>
                                        {/* <label className="mr-4">
                                            <input className="mr-1" type="radio" name="department" value="hotel" checked={selectedDepartment === 'hotel'} onChange={e => setSelectedDepartment(e.target.value)} />
                                            <span>{data && data.form_input_2_Hotel[props.lang]}</span>
                                        </label> */}
                                        <label className="mr-4">
                                            <input className="mr-1" type="radio" name="department" value="sale1" checked={selectedDepartment === 'sale1'} onChange={e => setSelectedDepartment(e.target.value)} />
                                            <span>{data && data.form_input_2_sale1[props.lang]}</span>
                                        </label>
                                        <label className="mr-4">
                                            <input className="mr-1" type="radio" name="department" value="resale" checked={selectedDepartment === 'resale'} onChange={e => setSelectedDepartment(e.target.value)} />
                                            <span>{data && data.form_input_2_sale2[props.lang]}</span>
                                        </label>
                                        <label className="mr-4">
                                            <input className="mr-1" type="radio" name="department" value="disposable_items" checked={selectedDepartment === 'disposable_items'} onChange={e => setSelectedDepartment(e.target.value)} />
                                            <span>{data && data.form_input_2_sale3[props.lang]}</span>
                                        </label>
                                        <label>
                                            <input className="mr-1" type="radio" name="department" value="household_appliances" checked={selectedDepartment === 'household_appliances'} onChange={e => setSelectedDepartment(e.target.value)} />
                                            <span>{data && data.form_input_2_sale4[props.lang]}</span>
                                        </label>
                                    </div>
                                    }
                                    {inputOTPStatus && data && React.createElement(listDepartment[selectedDepartment], {
                                        lang: props.lang,
                                        isDev: props.isDev,
                                        data: data,
                                        call_CpSale_status: call_CpSale_status,
                                        call_CpSale: call_CpSale,
                                        call_InputInfo: call_InputInfo,
                                        call_InputInfoCOD: call_InputInfoCOD
                                    })}
                                    {inputOTPStatus && <div className="flex justify-between items-center">
                                        <label>
                                            <input className="mr-1" type="checkbox" name="terms" value="1" checked={isTermsAccepted} onChange={e => setIsTermsAccepted(e.target.value)} />
                                            <span className="text-sm font-semibold">{data && data.form_input_info[props.lang]}</span>
                                        </label>
                                        {isTermsAcceptedError && (<span style={{ color: "#ff000096" }}>{isTermsAcceptedError}</span>)}
                                        <button onClick={handleSendText} className={"py-4 px-8 text-sm text-white font-semibold leading-none " + (data_inputInfoAI.length > 200 && data_inputInfoAI.length <= 500 && data_inputInfoCOD.length > 0 && isTermsAccepted && step2_SP_Status ? "bg-blue-500 hover:bg-blue-600" : "bg-blueGray-500 hover:bg-blueGray-600") + " hover:bg-blue-500 rounded"} type="submit">{data && data.form_input_2_sale_py_4[props.lang]}</button>
                                    </div>
                                    }
                                    {/* Bước 3 */}
                                    <ul>
                                        <li className="flex -mx-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                            <div className="px-4">
                                                <span className="flex w-16 h-16 mx-auto items-center justify-center text-2xl font-bold font-heading rounded-full bg-blue-50 text-blue-600">3</span>
                                            </div>
                                            <div className="px-4">
                                                <h3 className="my-4 text-xl font-semibold">{data && data.rg_3_title[props.lang]}</h3>
                                                <p className="text-blueGray-400 leading-loose">{data && data.rg_3_des[props.lang]}</p>
                                                <p className="text-blueGray-400 leading-loose">{data && data.rg_3_des1[props.lang]}</p>
                                            </div>
                                        </li>
                                    </ul>
                                    {inputOTPStatus && <div className="flex flex-wrap mb-4 -mx-3 wow animate__animated animate__fadeInUp animated" data-wow-delay=".3s">
                                        <div className="w-full px-3 mb-4 lg:mb-0">
                                            
                                            <br />
                                            <div className="flex items-center gap-4">
                                                <span className="mr-4 font-semibold">{data && data.form_input_mr4_2[props.lang]}</span>
                                                <div className="mb-4">
                                                    <a
                                                        href={inputFB}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            display: 'inline-block',
                                                            backgroundColor: '#4267B2', // Màu xanh Facebook
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            padding: '10px 20px',
                                                            borderRadius: '5px',
                                                            fontWeight: 'bold',
                                                            textDecoration: 'none',
                                                            transition: 'background-color 0.3s ease',
                                                        }}
                                                        onMouseEnter={(e) => (e.target.style.backgroundColor = '#365899')}
                                                        onMouseLeave={(e) => (e.target.style.backgroundColor = '#4267B2')}
                                                    >
                                                        Facebook
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-4">
                                                <span className="mr-4 font-semibold">{data && data.form_input_mr4_3[props.lang]}</span>
                                                <div className="mb-4">
                                                    <a
                                                        href={inputIns}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            display: 'inline-block',
                                                            background: 'linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)', // Màu gradient Instagram
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            padding: '10px 20px',
                                                            borderRadius: '5px',
                                                            fontWeight: 'bold',
                                                            textDecoration: 'none',
                                                            transition: 'opacity 0.3s ease',
                                                        }}
                                                        onMouseEnter={(e) => (e.target.style.opacity = '0.9')}
                                                        onMouseLeave={(e) => (e.target.style.opacity = '1')}
                                                    >
                                                        Instagram
                                                    </a>
                                                </div>
                                            </div>


                                            <div className="flex items-center gap-4">
                                                <span className="mr-4 font-semibold">{data && data.form_input_mr4_4[props.lang]}</span>
                                                <div className="mb-4">
                                                    <a
                                                        href={inputSP}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            display: 'inline-block',
                                                            backgroundColor: '#FF5722', // Màu cam Shopee
                                                            color: 'white',
                                                            textAlign: 'center',
                                                            padding: '10px 20px',
                                                            borderRadius: '5px',
                                                            fontWeight: 'bold',
                                                            textDecoration: 'none',
                                                            transition: 'background-color 0.3s ease',
                                                        }}
                                                        onMouseEnter={(e) => (e.target.style.backgroundColor = '#E64A19')}
                                                        onMouseLeave={(e) => (e.target.style.backgroundColor = '#FF5722')}
                                                    >
                                                        Shopee
                                                    </a>
                                                </div>
                                            </div>


                                            <span className="mr-4 font-semibold">{data && data.form_input_mr4_5[props.lang]}</span>
                                            <div>
                                                <img src={inputZL} alt="Zalo" width={"20%"} />
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* SỬ DỤNG HANDSUP CHAT NGAY!!! */}
            <div className="py-20 bg-blue-200 mx-auto flex justify-center" id="how-we-work">
                <div className="max-w-4xl bg-white rounded-lg shadow-lg p-8">
                    <div className="text-center mb-12">
                        <h2 className="text-4xl font-bold font-heading wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                            {data && data.form_title_1[props.lang]}
                        </h2>
                        <br />
                        {/* <a className="py-4 px-12 text-3xl text-black font-heading leading-none bg-blue-200 hover:bg-blue-500 rounded" href="/login">
                            {data && data.form_title_3[props.lang]}
                        </a> */}
                        {/* <div className="container">
                            <div className="row min-vh-100 align-items-center">
                                <div className="col-12 text-center">
                                    <div className={"py-3 px-6 text-2xl text-white font-bold uppercase leading-none bg-blue-400 hover:bg-blue-500 rounded"}>
                                        {data && data.form_title_4[props.lang]}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="container">
                            <div className="row min-vh-100 align-items-center">
                                <div className="col-12 text-center">
                                    <div className={"py-3 px-6 text-2xl text-white font-bold uppercase leading-none bg-blue-400 hover:bg-blue-500 rounded"}>
                                        <a href="/login">
                                            {data && data.form_title_3[props.lang]}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                        <br />
                        <p className="text-blueGray-400 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            {data && data.form_title_2[props.lang]}
                        </p>
                    </div>
                    <div className="flex flex-wrap justify-center md:justify-start -mx-3 text-center">
                        <div className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-12 wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <svg className="mb-6 h-8 w-8 mx-auto text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                            </svg>
                            <div className="leading-relaxed">
                                <span className="text-sm text-blueGray-400">{data && data.form_email_0[props.lang]}</span>
                                <p>{data && data.form_email_1_title[props.lang]}: {data && data.form_email_1[props.lang]}</p>
                            </div>
                        </div>

                        <div className="w-full sm:w-1/2 lg:w-1/3 px-3 mb-12 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                            <svg className="mb-6 h-8 w-8 mx-auto text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            </svg>
                            <div className="leading-relaxed">
                                <span className="text-sm text-blueGray-400">{data && data.form_addr_0[props.lang]}</span>
                                <p>{data && data.form_addr_2_title[props.lang]}: {data && data.form_addr_2[props.lang]}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <section className="py-12 md:py-16 lg:py-32 overflow-x-hidden" id="key-features">
                <div className="container px-4 mx-auto">
                    <div className="flex flex-wrap lg:flex-nowrap">
                        <div className="w-full lg:w-1/2">
                            <div className="lg:py-6 lg:pr-32 wow animate__animated animate__fadeIn" data-wow-delay=".3s">
                                <div className="mb-4">
                                    <span className="text-xs py-1 px-3 text-blue-500 font-semibold bg-blue-50 rounded-xl wow animate__animated animate__fadeInDown" data-wow-delay=".9s">{data && data.form_input_text_xs[props.lang]}</span>
                                    <h2 className="text-4xl mt-5 font-bold font-heading wow animate__animated animate__fadeInUp" data-wow-delay=".3s">{data && data.form_input_text_4xl[props.lang]}</h2>
                                </div>
                                <div className="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                    <div className="w-8 mr-5 text-blue-500">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="mb-2 text-xl font-semibold font-heading">{data && data.form_input_mb_2[props.lang]}</h3>
                                        <p className="text-blueGray-400 leading-loose">{data && data.form_input_text_blueGray[props.lang]}</p>
                                    </div>
                                </div>
                                <div className="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                    <div className="w-8 mr-5 text-blue-500">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"></path>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="mb-2 text-xl font-semibold font-heading">{data && data.form_input_mb_2_2[props.lang]}</h3>
                                        <p className="text-blueGray-400 leading-loose">{data && data.form_input_text_blueGray2[props.lang]}</p>
                                    </div>
                                </div>
                                <div className="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                                    <div className="w-8 mr-5 text-blue-500">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 className="mb-2 text-xl font-semibold font-heading">{data && data.form_input_h3[props.lang]}</h3>
                                        <p className="text-blueGray-400 leading-loose">{data && data.form_input_text_blueGray3[props.lang]}</p>
                                        <p className="text-blueGray-400 leading-loose">{data && data.form_input_text_blueGray4[props.lang]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative w-full lg:w-1/2 my-12 lg:my-0">
                            <div className="wow animate__animated animate__fadeInRight" data-wow-delay=".5s">
                                <img className="jump relative mx-auto rounded-xl w-full z-10" src="/assets/imgs/noi-bat.png" alt="" />
                                <img className="absolute top-0 left-0 w-40 -ml-12 -mt-12" src="/assets/imgs/elements/blob-tear.svg" alt="" />
                                <img className="absolute bottom-0 right-0 w-40 -mr-12 -mb-12" src="/assets/imgs/elements/blob-tear.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-20 xl:bg-contain bg-top bg-no-repeat" style={{ "backgroundImage": "url('assets/imgs/backgrounds/intersect.svg')" }}>
                <div className="container px-4 mx-auto">
                    <div className="text-center mb-16">
                        <h2 className="max-w-lg mx-auto mb-4 text-4xl font-bold font-heading wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                            <span>{data && data.form_input_span1[props.lang]}</span>
                            <span className="text-blue-500"> {data && data.form_input_span2[props.lang]} </span>
                            <span>{data && data.form_input_span3[props.lang]}</span>
                        </h2>
                        <p className="max-w-sm mx-auto text-lg text-blueGray-400 wow animate__animated animate__fadeInDown" data-wow-delay=".5s">{data && data.form_input_max_w_0[props.lang]}</p>
                    </div>
                    <div className="flex flex-wrap -mx-3">
                        <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                            <div className="hover-up-5 pt-16 pb-8 px-4 text-center bg-white rounded shadow wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <img className="h-20 mb-6 mx-auto" src="/assets/imgs/icons/startup.svg" alt="" />
                                <h3 className="mb-2 text-4xl font-bold font-heading">{data && data.form_input_healing_1[props.lang]}</h3>
                                <span className="text-4xl text-blue-500 font-bold font-heading">{data && data.form_input_healing_2[props.lang]}</span>
                                <p className="mt-2 mb-8 text-blueGray-400">{data && data.form_input_healing_3[props.lang]}</p>
                                <div className="flex flex-col items-center mb-8">
                                    <ul className="text-blueGray-400">
                                        <li className="flex items-center mb-3">
                                            <svg className="flex-shrink-0 w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_span1_1[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="flex-shrink-0 w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_span1_2[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="flex-shrink-0 w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_span1_3[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="flex-shrink-0 w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_span1_4[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="flex-shrink-0 w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_span1_5[props.lang]}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <a className="block sm:inline-block py-4 px-6 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded" href="/login">{data && data.form_input_block_1[props.lang]}</a>
                                    <a className="block sm:inline-block py-4 px-6 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white border border-blueGray-200 hover:border-blueGray-300 rounded" href={"/s" + props.data_master.route["Pricing"].link[props.lang]}>{data && data.form_input_block_2[props.lang]}</a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                            <div className="hover-up-5 pt-16 pb-8 px-4 text-center text-white bg-blue-500 rounded shadow wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <img className="h-20 mb-6 mx-auto" src="/assets/imgs/icons/agency.svg" alt="" />
                                <h3 className="mb-2 text-4xl font-bold font-heading">{data && data.form_input_h3_1[props.lang]}</h3>
                                <span className="text-4xl font-bold font-heading">{data && data.form_input_h3_2[props.lang]}</span>
                                <p className="mt-2 mb-8">{data && data.form_input_h3_3[props.lang]}</p>
                                <div className="flex flex-col items-center mb-8">
                                    <ul>
                                        <li className="flex items-center mb-3">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span>{data && data.form_input_h3_4[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_h3_5[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span>{data && data.form_input_h3_6[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span>{data && data.form_input_h3_7[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center mb-3">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span>{data && data.form_input_h3_8[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_h3_13[props.lang]}</span>
                                        </li>
                                        <li className="flex items-center">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_h3_12[props.lang]}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <a className="block sm:inline-block py-4 px-6 mb-4 sm:mb-0 sm:mr-3 text-xs text-blue-500 font-semibold leading-none bg-white hover:bg-blueGray-50 rounded" href="/login">{data && data.form_input_block_sm1[props.lang]}</a>
                                    <a className="block sm:inline-block py-4 px-6 text-xs font-semibold leading-none border border-blue-500 hover:border-blue-400 rounded" href={"/s" + props.data_master.route["Pricing"].link[props.lang]}>{data && data.form_input_block_sm2[props.lang]}</a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 px-3 mb-6">
                            <div className="hover-up-5 pt-16 pb-8 px-4 text-center bg-white rounded shadow wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                                <img className="h-20 mb-6 mx-auto" src="/assets/imgs/icons/enterprise.svg" alt="" />
                                <h3 className="mb-2 text-4xl font-bold font-heading">{data && data.form_input_h3_9[props.lang]}</h3>
                                <span className="text-4xl text-blue-500 font-bold font-heading">{data && data.form_input_h3_10[props.lang]}</span>
                                <p className="mt-2 mb-8 text-blueGray-400">{data && data.form_input_h3_11[props.lang]}</p>
                                <div className="flex flex-col  mb-8">
                                    <ul className="text-blueGray-400">
                                        <li className="flex items-center mb-3">
                                            <svg className="flex-shrink-0 w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_flex_mb1[props.lang]}</span>
                                        </li>
                                        <li className="flex mb-3 items-center">
                                            <svg className="w-6 h-6 mr-2 text-green-500 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span className="text-left block">{data && data.form_input_flex_mb2[props.lang]}</span>
                                        </li>
                                        {/* <li className="flex mb-3">
                                            <svg className="w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                            </svg>
                                            <span>{data && data.form_input_flex_mb3[props.lang]}</span>
                                        </li> */}
                                    </ul>
                                </div>
                                <div>
                                    <a className="block sm:inline-block py-4 px-6 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded" href={"/s" + props.data_master.route["Contact"].link[props.lang]}>{data && data.form_input_contact_1[props.lang]}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CpFooter {...props}></CpFooter>
        </div>
        <CpLoading {...props}></CpLoading>
    </React.Fragment >
    )
};

export default Phome;