import React, { useEffect } from 'react'; 
 
function ListUser(props) {
    useEffect(() => {
    }, []);

    return (<div id="friends" className="friends">
        <div className="profile friends-photo">
            {props.data_user && <img src={props.data_user.avatar} alt={props.data_user.name} />}
        </div>
        <div className="friends-credent">
            {props.data_user && <span className="friends-name">{props.data_user.name}</span>}
        </div>
    </div>)
};

export default ListUser;