import React, { useEffect } from 'react';

function UserChat(props) {
    useEffect(() => {
    }, []);
    
    return (
        <div id="friends-chat" className="friends-chat">
            <span dangerouslySetInnerHTML={{ __html: props.data_content }} />
            <div className="profile friends-chat-photo">
                {props.user_data && <img src={props.user_data.avatar} alt={props.user_data.name} />}
            </div>
            <div className="friends-chat-content">
                {props.user_data && <p className="friends-chat-name">{props.user_data.name}</p>}
                {props.data_content && <p className="friends-chat-balloon">{props.data_content.content}</p>}
                {props.data_content && <h5 className="chat-datetime">{props.data_content.time}</h5>}
            </div>
        </div>
    );
}

export default UserChat;
