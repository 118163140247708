import React, { useEffect, useState } from 'react';
import CpHeader from "../component/cp_header"
import CpFooter from "../component/cp_footer"
import CpLoading from "../component/cp_loading"
import { loadContent } from "../lib/libFly";

function PContact(props) {
    const [data, setData] = useState();
    useEffect(() => {
        let aa_ = loadContent("contact");
        aa_.then((result) => {
            if (result.title !== undefined && result.title[props.lang] !== undefined)
                document.title = result.title[props.lang];
            setData(result);
        })

        window.abcC();
    }, [props.lang]);

    return (<React.Fragment>
        <div className="main">
            <CpHeader {...props}></CpHeader>
            <section className="relative -mt-24 pt-24">
                <div className="hidden lg:block absolute inset-0 w-1/2 ml-auto bg-blueGray-100 z-0" style={{ "z-index": "-1" }}></div>
                <div className="container">
                    <div className="flex flex-wrap items-center -mx-3">
                        <div className="w-full lg:w-1/2 px-3">
                            <div className="py-12">
                                <div className="max-w-lg lg:max-w-md mx-auto lg:mx-0 mb-8 text-center lg:text-left">
                                    <h2 className="text-3xl lg:text-4xl mb-4 font-bold font-heading wow animate__animatedanimated animate__fadeIn">{data && data.aa[props.lang]} <span className="text-blue-500">People</span>, <br />Committed to the future</h2>
                                    <p className="text-blueGray-400 leading-relaxed wow animate__animatedanimated animate__fadeIn">We are <strong className="text-blue-500">Monst</strong>, a Creative Design <span className="typewrite d-inline text-brand" data-period="3000" data-type='["Web Agency", "Social Marketing" ]'></span></p>
                                    <p className="text-blueGray-400 leading-relaxed wow animate__animatedanimated animate__fadeIn mt-3 text-sm">Helping you maximize operations management with digitization</p>
                                </div>
                                <div className="text-center lg:text-left">
                                    <a className="tracking-wide hover-up-2 block sm:inline-block py-4 px-8 mb-4 sm:mb-0 sm:mr-3 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded wow animate__animatedanimated animate__fadeInUp" href="#key-features">Key Features</a>
                                    <a className="block hover-up-2 sm:inline-block py-4 px-8 text-xs text-blueGray-500 hover:text-blueGray-600 text-center font-semibold leading-none bg-white border border-blueGray-200 hover:border-blueGray-300 rounded wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s" href="#how-we-work">How We Work?</a>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 px-3 lg:bg-blueGray-10 mb-12 lg:mb-0 pb-10">
                            <div className="flex items-center justify-center">
                                <img className="lg:max-w-lg" src="assets/imgs/illustrations/team.svg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-20">
                <div className="container">
                    <div className="max-w-2xl mx-auto text-center">
                        <div className="max-w-md mb-8 mx-auto">
                            <span className="inline-block py-1 px-3 text-xs font-semibold bg-blue-100 text-blue-600 rounded-xl wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".1s">Contact Us</span>
                            <h2 className="mt-2 text-4xl font-bold font-heading wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".s">We will <span className="text-blue-500">be glad</span> to hear from you!</h2>
                        </div>
                        <div>
                            <form>
                                <div className="mb-4 text-sm wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".5s">
                                    <span className="mr-4 font-semibold">Departament:</span>
                                    <label className="mr-4">
                                        <input className="mr-1" type="radio" name="department" value="1" checked="" />
                                        <span>Support</span>
                                    </label>
                                    <label>
                                        <input className="mr-1" type="radio" name="department" value="2" />
                                        <span>Sales</span>
                                    </label>
                                </div>
                                <div className="mb-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s">
                                    <input className="w-full p-4 text-xs font-semibold leading-none bg-blueGray-50 rounded outline-none" type="text" placeholder="Subject" />
                                </div>
                                <div className="mb-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s">
                                    <input className="w-full p-4 text-xs font-semibold leading-none bg-blueGray-50 rounded outline-none" type="text" placeholder="Name" />
                                </div>
                                <div className="mb-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s">
                                    <input className="w-full p-4 text-xs font-semibold leading-none bg-blueGray-50 rounded outline-none" type="email" placeholder="name@example.com" />
                                </div>
                                <div className="mb-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s"><textarea className="w-full h-24 p-4 text-xs font-semibold leading-none resize-none bg-blueGray-50 rounded outline-none" placeholder="Message..."></textarea></div>
                                <div className="mb-4 wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s">
                                    <label className="flex px-2 bg-blueGray-50 rounded">
                                        <input className="hidden" type="file" name="Choose file" />
                                        <span className="my-1 ml-auto px-4 py-3 text-xs text-white font-semibold leading-none bg-blueGray-500 hover:bg-blueGray-600 rounded cursor-pointer"> Browse</span>
                                    </label>
                                </div>
                                <div className="flex justify-between items-center wow animate__animatedanimated animate__fadeInUp" data-wow-delay=".3s">
                                    <label>
                                        <input className="mr-1" type="checkbox" name="terms" value="1" />
                                        <span className="text-sm font-semibold">I agree to terms and conditions.</span>
                                    </label>
                                    <button className="py-4 px-8 text-sm text-white font-semibold leading-none bg-blue-500 hover:bg-blue-700 rounded" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <CpFooter {...props}></CpFooter>
        </div>
        <CpLoading {...props}></CpLoading>
    </React.Fragment >)
};

export default PContact;