import React, { useEffect, useState } from 'react';
import CpHeader from "../component/cp_header"
import CpFooter from "../component/cp_footer"
import CpLoading from "../component/cp_loading"
import { loadContent, markdownForTemplate } from "../lib/libFly";

function PPrivacyPolicy(props) {
    const [data, setData] = useState();
    useEffect(() => {
        let aa_ = loadContent("privacypolicy");
        aa_.then((result) => {
            if (result.title !== undefined && result.title[props.lang] !== undefined)
                document.title = result.title[props.lang];

            //có vài thay đổi trong template về markdownSB -> bản dành riêng
            markdownForTemplate(result);

            setData(result);
        })

        window.abcC();
    }, [props.lang]);

    return (<React.Fragment>
        <div className="main">
            <CpHeader {...props}></CpHeader>
            <section class="py-20 pb-8 overflow-x-hidden">
                <div class="container">
                    <div class="flex flex-wrap">
                        <div class="relative w-full lg:w-1/2 h-128 mb-20 lg:mb-0 wow animate__animated animate__fadeIn" data-wow-delay=".1s">
                            <div class="absolute top-0 right-0 h-full w-full mt-6 -mr-6 bg-blueGray-100 rounded-xl"></div>
                            <img class="absolute top-0 right-0 h-full w-full rounded-xl object-cover object-top" src="/assets/imgs/backgrounds/chinh-sach.png" alt="" />
                        </div>
                        <div class="w-full lg:w-1/2 lg:pl-24 my-auto">
                            <img src="/assets/imgs/icons/quote.svg" alt="" class="wow animate__animated animate__fadeIn" data-wow-delay=".2s" />
                            <h2 class="my-4 text-4xl font-bold font-heading wow animate__animated animate__fadeIn" data-wow-delay=".3s" style={{ "text-align": "justify" }}>{data && data.policy_title[props.lang]}</h2>
                            <p class="mb-1 text-lg">
                                <strong class="text-blue-500 wow animate__animated animate__fadeIn" data-wow-delay=".4s">{data && data.policy_title_group[props.lang]}</strong>
                            </p>
                            <p class="text-gray-500 text-xs wow animate__animated animate__fadeInUp" data-wow-delay=".5s">{data && data.policy_title_thanks[props.lang]}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-2">
                <div class="container">
                    <div class="flex flex-wrap lg:flex-nowrap">
                        <div class="w-full lg:w-auto">
                            <div class="lg:pl-32">
                                <div class="mb-4">
                                    {/* <span class="text-xs py-1 px-3 text-blue-600 font-semibold bg-blue-50 rounded-xl wow animate__animated animate__fadeInUp" data-wow-delay=".1s">Chính sách bảo mật và Điều khoản</span> */}
                                    <h2 class="text-4xl mt-3 font-bold font-heading wow animate__animated animate__fadeInUp" data-wow-delay=".3s">{data && data.detail_title[props.lang]}</h2>
                                </div>
                                <div class="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
                                    <div class="w-8 mr-5 text-blue-500">
                                        <svg class="w-8 h-8 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 class="mb-2 text-xl font-semibold font-heading">{data && data.detail_1_titile[props.lang]}</h3>
                                        <p class="text-blueGray-400 leading-loose">{data && data.detail_1_des[props.lang]}</p>
                                    </div>
                                </div>
                                <div class="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
                                    <div class="w-8 mr-5 text-blue-500">
                                        <svg class="w-8 h-8" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 class="mb-2 text-xl font-semibold font-heading">{data && data.detail_2_titile[props.lang]}</h3>
                                        <p class="text-blueGray-400 leading-loose">{data && data.detail_2_des[props.lang]}</p>
                                    </div>
                                </div>
                                <div class="flex items-start py-4 wow animate__animated animate__fadeInUp" data-wow-delay=".9s">
                                    <div class="w-8 mr-5 text-blue-500">
                                        <svg class="w-8 h-8 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"></path>
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"></path>
                                        </svg>
                                    </div>
                                    <div>
                                        <h3 class="mb-2 text-xl font-semibold font-heading">{data && data.detail_3_titile[props.lang]}</h3>
                                        <p class="text-blueGray-400 leading-loose">{data && data.detail_31_des[props.lang]}</p>
                                        <p class="text-blueGray-400 leading-loose">{data && data.detail_32_des[props.lang]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CpFooter {...props}></CpFooter>
        </div>
        <CpLoading {...props}></CpLoading>
    </React.Fragment >)
};

export default PPrivacyPolicy;